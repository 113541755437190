import React from "react";
import PropTypes from "prop-types";
import ServiceSelectField from "./ServiceSelectField";
import { useTranslation } from 'react-i18next';

const releaseStatuses = [
  { value: 'MILD', label: 'LOW' },
  { value: 'MODERATE', label: 'MODERATE' },
  { value: 'HIGH', label: 'HIGH' },
];

const ClassificationSelectField = ({ label, name, ...other }) => {
  const { t } = useTranslation();

  return (
    <ServiceSelectField
      name={name}
      optionmap={releaseStatuses}
      label={t('manageResults_labels.classification')}
      {...other}
    />
  );
};

ClassificationSelectField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ClassificationSelectField
