import PropTypes from "prop-types";
import React from "react";
import { withStyles } from "@material-ui/core/styles/index";
import { compose } from "recompose";
import Typography from "@material-ui/core/Typography/Typography";
import Card from "@material-ui/core/Card/Card";
import MenuList from "@material-ui/core/MenuList/MenuList";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Divider from "@material-ui/core/Divider/Divider";
import { fabAddButton } from "../../common/fields/components/FabButtonComponent";
import Utils from "../../common/Utils";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid/Grid";
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  content: {
    overflow: "auto",
    maxHeight: "25vh",
  },
});

const DocumentVersion = ({
  versions,
  classes,
  labels,
  versionSelectedHandler,
  selectedVersion,
  windowHandler,
}) => {

  const { t } = useTranslation();

  let renderSingleItem = (version, selectedVersion) => {
    const timestamp = version.published
      ? version.releaseDateTime
      : version.creationDateTime;
    const label = version.published
      ? t('documents_section.versions.release_date')
      : t('documents_section.versions.creation_date');
    return (
      <div key={version.id}>
        <MenuItem
          selected={
            selectedVersion !== null && selectedVersion.id === version.id
          }
          onClick={() => versionSelectedHandler(version)}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={3}>
              <ListItemText primary={version.name} />
            </Grid>
            {version.published && (
              <Grid item xs={3}>
                <Chip color="primary" label={t('documents_section.published')} />
              </Grid>
            )}
            <Grid container item xs={5}>
              <Grid item xd={6}>
                {label}:
              </Grid>
              <Grid item xd={6}>
                <i>{Utils.shortDateRenderer(new Date(timestamp))}</i>
              </Grid>
            </Grid>
          </Grid>
        </MenuItem>
        <Divider />
      </div>
    );
  };

  return (
    <React.Fragment>
      <Typography variant="h5" color="secondary">
        {t('documents_section.versions.title')}
      </Typography>
      <Card className={classes.content}>
        <MenuList style={{ padding: 0 }}>
          {versions.map((item) => renderSingleItem(item, selectedVersion))}
        </MenuList>
      </Card>
      {fabAddButton(t('documents_section.versions.add_tooltip'), windowHandler)}
    </React.Fragment>
  );
};

DocumentVersion.propTypes = {
  versions: PropTypes.array.isRequired,
  versionSelectedHandler: PropTypes.func.isRequired,
  selectedVersion: PropTypes.object,
  newVersionAddedHandler: PropTypes.func,
  windowHandler: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
)(DocumentVersion);
