import React from "react";
import { Route, Switch } from "react-router-dom";
import LoginCallbackHandlerContainer from "../Auth/LoginCallbackHandlerContainer";
import LogoutCallbackHandlerContainer from "../Auth/LogoutCallbackHandlerContainer";
import App from "../App";
import Signup from "../Signup/Signup";
import Done from "../Signup/Done";
import ResetPassword from "../ResetPassword/ResetPassword";

const OuterRouting = () => (
  <Switch>
    <Route
      exact
      path="/login-callback"
      render={(props) => {
        return <LoginCallbackHandlerContainer {...props} />;
      }}
    />
    <Route
      exact
      path="/logout-callback"
      render={(props) => {
        return <LogoutCallbackHandlerContainer {...props} />;
      }}
    />

    <Route path="/signup" component={Signup} />
    <Route path="/done" component={Done} />
    <Route path="/resetPassword" component={ResetPassword} />

    <Route path="/"component={App} />
  </Switch>
);

export default OuterRouting;
