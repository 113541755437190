import PropTypes from "prop-types";
import React from "react";
import { withStyles } from "@material-ui/core/styles/index";
import { compose } from "recompose";
import Draft from "../../../RtfEditor/Draft";
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import TranslationsSelectField from "../../../common/fields/selectFields/TranslationsSelectField";
import { reduxForm } from "redux-form";
import renderAgreement from "../renderAgreement";
import { useTranslation } from "react-i18next";

const styles = (theme) => ({
  content: {
    display: "flex",
    width: "100%",
  },
  child: {
    flex: 1,
    margin: theme.spacing(1),
  },
  renderDoc: {
    marginTop: 100,
    height: "60vh",
    overflow: "auto",
  },
});

const reduxFormName = "editDocumentForm";

const EditDocumentRepresentation = (props) => {

  const { t } = useTranslation();

  let { windowHandler, onDraftHandler, onFinishHandler } = props;

  let { translation, translationsToCompare, compareTranslation } = props;

  let { docEdit, compareTextDocument } = props;

  let { handleChangeTranslationCompareLanguage } = props;

  let { onChange, editorState } = props;

  let { classes } = props;

  const distribute =
    compareTextDocument !== null
      ? { left: 6, right: 6 }
      : { left: 12, right: 0 };

  const dir = compareTranslation ? compareTranslation.textDirection : "LTR";

  return (
    <Grid container spacing={1}>
      {translation !== null && (
        <Grid item xs={4}>
          <Typography variant="h5" color="secondary">
            {translation.languageName}
          </Typography>
        </Grid>
      )}

      <Grid item xs={8}>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Button color="primary" onClick={onFinishHandler}>
              {t('documents_section.edit_document_representation.finish')}
            </Button>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={onDraftHandler}>
              {t('documents_section.edit_document_representation.in_progress')}
            </Button>
          </Grid>
          <Grid item>
            <Button color="secondary" onClick={windowHandler}>
              {t('documents_section.edit_document_representation.cancel')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item>
          {translation !== null && (
            <Grid item style={{ paddingLeft: "4px" }}>
              <div style={{ marginTop: "20px" }}>
                {t('compareWith')} <br />
                <TranslationsSelectField
                  selectedTranslation={compareTranslation}
                  translations={translationsToCompare}
                  handleChange={handleChangeTranslationCompareLanguage}
                />
              </div>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={distribute.left}>
            <Draft
              docEdit={docEdit}
              editMode={true}
              onChange={onChange}
              editorState={editorState}
            />
          </Grid>

          {compareTextDocument && (
            <Grid item xs={distribute.right}>
              <div dir={dir} className={classes.renderDoc}>
                {compareTextDocument !== null &&
                  renderAgreement(compareTextDocument.content)}
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

EditDocumentRepresentation.propTypes = {
  docEdit: PropTypes.array,
  windowHandler: PropTypes.func.isRequired,
  onFinishHandler: PropTypes.func.isRequired,
  onDraftHandler: PropTypes.func.isRequired,
  editorState: PropTypes.any,
  translationsToCompare: PropTypes.array,
};

export default compose(
  withStyles(styles),
  reduxForm({
    form: reduxFormName,
  })
)(EditDocumentRepresentation);
