import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { reduxForm } from "redux-form";
import { PagedTableContainer } from "../../common/components/PagedTable/PagedTableContainer";
import {
  EXPORT_RESULTS_SERVICE,
  RESULT_SERVICE,
} from "../../common/services/availableServices";
import { compose } from "recompose";
import { columnType } from "../../common/components/PagedTable/columnType";
import SearchTextField from "../../common/fields/SearchTextField";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import ClassificationComponent from "../common/ClassificationComponent";
import ResultTypeSelectField from "../../common/fields/selectFields/ResultTypeSelectField";
import ClassificationSelectField from "../../common/fields/selectFields/ClassificationSelectField";
import { permission } from "../../Auth/permissions";
import PermissionContainer from "../../Auth/PermissionContainer";
import ClinicsSelectField from "../../common/fields/selectFields/ClinicsSelectField";
import Utils from "../../common/Utils";
import ExportExcelButton from "../../common/components/ExportExcelButton";
import PatientResultView from "./PatientResultView";
import Dialog from "@material-ui/core/Dialog";
import { managePageStyles } from "../../styles";
import PendingResultSelectField from "../../common/fields/selectFields/PendingResultSelectField";
import Tooltip from "@material-ui/core/es/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import HelpIcon from "@material-ui/icons/HelpOutline";
import { roles } from "../../Auth/roles";
import { renderFinalScore } from '../../utils/calprotection';

import { withTranslation } from 'react-i18next';

const styles = () => ({
  dialogPaperClass: {
    padding: 0,
    width: 1100,
  },
  tooltip: {
    maxWidth: 200,
  },
});

class ManagePatientResults extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      criteria: { archived: false },
      openModal: false,
      id: NaN,
      loadingTableData: true,
    };
    this.tableColumnSetup();
  }

  tableColumnSetup() {
    this.columns = [
      {
        key: "date",
        type: columnType.text,
        customCellRenderer: this.dateCellRenderer,
      },
      { key: "systemId", type: columnType.text },
      { key: "sampleId", type: columnType.text },
        // {
        //   key: "externalPatientId",
        //   type: columnType.text,
        //   orderProperty: "patient.externalId",
        // },
        // {
        //   key: "patientInfo",
        //   type: columnType.text,
        //   orderProperty: ["patient.firstName", "patient.lastName"],
        // },
      { key: "clinicName", type: columnType.text, sortable: false },
      { key: "batchNumber", type: columnType.text, sortable: false },
      {
        key: "finalConcentration",
        type: columnType.numeric,
        customCellRenderer: this.finalConcentrationCellRenderer,
      },
      {
        key: "overallResult",
        type: columnType.text,
        customCellRenderer: this.overallResultCellRenderer,
        sortable: false,
      },
      //   {
      //     key: "labResult",
      //     type: columnType.text,
      //     customCellRenderer: this.labResultCellRenderer,
      //     sortable: false,
      //   },
      {
        key: "details",
        type: columnType.text,
        customCellRenderer: this.detailsCellRenderer,
        sortable: false,
      },
    ];
  }

  overallResultCellRenderer = (rowId, columnValue) => {
    return <ClassificationComponent classification={columnValue} />;
  };

  finalConcentrationCellRenderer = (rowId, columnValue, row) => {
    return renderFinalScore(columnValue, this.props.t('between'), row.finalScore);
  };

  detailsCellRenderer = (rowId, columnValue) => {
    return (
      <Button onClick={this.detailsButtonClickHandler(rowId)}>
        {this.props.t('buttons.details')}
      </Button>
    );
  };

  dateCellRenderer = (rowId, columnValue) => {
    return Utils.dateRenderer(columnValue);
  };

  labResultCellRenderer = (rowKey, columnValue) => {
    if (columnValue !== undefined) {
      if (columnValue === true)
        return this.props.t('manageResults_labels.result_types.lab');
      else if (columnValue === false)
        return this.props.t('manageResults_labels.result_types.home');
    }
  };

  detailsButtonClickHandler = (rowId) => (event) => {
    this.setState({
      openModal: true,
      id: rowId,
    });
  };

  searchButtonClickHandler = (values) => {
    let queryParams = {
      search: values.search,
    };

    if ("labResult" in values) {
      queryParams = {
        ...queryParams,
        labResult: values.labResult,
      };
    }
    if ("overallResult" in values) {
      queryParams = {
        ...queryParams,
        overallResult: values.overallResult,
      };
    }

    if ("clinic" in values) {
      queryParams = {
        ...queryParams,
        "patient.employee.clinic.id": values.clinic,
      };
    }

    if ("archived" in values) {
      queryParams = {
        ...queryParams,
        archived: values.archived,
      };
    }

    this.setState({
      criteria: queryParams,
      loadingTableData: true,
    });
  };

  successHandler = () => {
    this.setState({ id: NaN, openModal: false, loadingTableData: true });
  };

  closeWindowHandler = () => {
    this.setState({ id: NaN, openModal: false });
  };

  loadingDataFinishHandler = () => {
    this.setState({
      loadingTableData: false,
    });
  };

  render() {
    const { handleSubmit, classes, t } = this.props;
    const { criteria, id, openModal, loadingTableData } = this.state;

    return (
      <div>
        <Dialog
          classes={{ paper: classes.dialogPaperClass }}
          maxWidth={false}
          open={openModal}
          onClose={this.closeWindowHandler}
        >
          <PatientResultView
            resultId={id}
            onClose={this.closeWindowHandler}
            onSuccess={this.successHandler}
          />
        </Dialog>

        <Grid container className={classes.header}>
          <Grid item xs={8}>
            <Typography variant="h5" color="secondary">
              {t('manageResults_labels.page_title')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid item>
                <ExportExcelButton
                  service={EXPORT_RESULTS_SERVICE}
                  criteria={this.state.criteria}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <form onSubmit={handleSubmit(this.searchButtonClickHandler)}>
          <Grid spacing={2} container className={classes.criteria}>
            {/* <Grid item md={3}>
              <SearchTextField
                name="search"
                className={classes.criteriaField}
              />
            </Grid> */}
            <Grid item md={4} xs={12}>
              <div style={{ marginTop: 11 }}>
                <ClassificationSelectField
                  name="overallResult"
                  className={classes.criteriaField}
                />
              </div>
            </Grid>
            {/* <Grid item md={2}>
              <div style={{ marginTop: 11 }}>
                <ResultTypeSelectField
                  name="labResult"
                  className={classes.criteriaField}
                />
              </div>
            </Grid> */}
            {/* <Grid item container md={2} style={{ marginTop: 16 }}>
              <PermissionContainer
                allowedPermissions={[
                  permission.pendingResults_view_all,
                  permission.pendingResults_view_clinic,
                  permission.pendingResults_view_your,
                ]}
                exceptRoles={[roles.CLINIC_USER_FULL_INSIGHT]}
              >
                <Grid item md={10}>
                  <PendingResultSelectField
                    name="archived"
                    className={classes.criteriaField}
                  />
                </Grid>
                <Grid item md={2}>
                  <Tooltip
                    title={specLabels.pendingResultTooltip}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <IconButton>
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </PermissionContainer>
            </Grid> */}
            <Grid item md={4} xs={12}>
              <div style={{ marginTop: 11 }}>
                <PermissionContainer
                  allowedPermissions={[permission.patientResult_view_all]}
                >
                  <ClinicsSelectField
                    name="clinic"
                    className={classes.criteriaField}
                  />
                </PermissionContainer>
              </div>
            </Grid>
            <Grid
              item
              container
              md={4}
              xs={12}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button variant="contained" color="primary" type="submit">
                {t('buttons.search')}
              </Button>
            </Grid>
          </Grid>
        </form>
        <PagedTableContainer
          rowKey="id"
          orderBy="date"
          order="asc"
          columns={this.columns}
          criteria={criteria}
          service={RESULT_SERVICE}
          loadingData={loadingTableData}
          onLoadingDataFinish={this.loadingDataFinishHandler}
          errorMessage={t('table.error_message')}
          emptyResultMessage={t('table.empty_result')}
          columnLabels={t('manageResults_labels.columns', { returnObjects: true })}
        />
      </div>
    );
  }
}

export default compose(
  withStyles(
    (theme) => ({
      ...managePageStyles(theme),
      ...styles(theme),
    }),
    { withTheme: true }
  ),
  withTranslation(),
  reduxForm({
    form: "ResultSearch",
    initialValues: {
      archived: false,
    },
  }),
)(ManagePatientResults);
