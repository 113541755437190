import "babel-polyfill";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { unregister } from "./registerServiceWorker";
import reducer from "./reducers";
import "./index.css";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import AuthenticationService from "./Auth/AuthenticationService";
import LoadingIndicator from "./common/components/LoadingIndicator";
import { persistReducer, persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storageSession from 'redux-persist/lib/storage/session'
import { BrowserRouter } from "react-router-dom";
import { styles } from "./styles";
import OuterRouting from "./routing/OuterRouting";
import { ThemeProvider } from "@material-ui/styles";

import "./i18n.js";

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export const authenticationService = new AuthenticationService();
ReactDOM.render(
  <Suspense fallback="loading">
    <Provider store={store}>
      <PersistGate
        loading={<LoadingIndicator />}
        persistor={persistStore(store)}
      >
        <BrowserRouter>
          <ThemeProvider theme={styles}>
            <OuterRouting />
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </Suspense>,
  document.getElementById("root")
);
unregister();
