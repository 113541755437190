import PropTypes from "prop-types";
import { DOCUMENTS_TRANSLATION_SERVICE } from "../../common/services/availableServices";
import DocumentTranslation from "./DocumentTranslation";
import React, { Component } from "react";
import AddNewTranslationView from "./AddNewTranslationView";
import PopupWindow from "../../common/components/PopupWindow";

class DocumentTranslationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      translations: [],
      selectedTranslation: null,
      openModal: false,
    };
    this.translationHandler = this.translationHandler.bind(this);
    this.windowHandler = this.windowHandler.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { version, checkPublish } = this.props;
    if (version !== null && prevProps.version == null) {
      this.getTranslationsPerVersion(version.id);
    } else if (version !== null && version.id !== prevProps.version.id) {
      this.getTranslationsPerVersion(version.id);
    } else if (checkPublish) {
      this.isReadyToPublishHandler(this.state.translations);
    }
  }

  getTranslationsPerVersion(versionId) {
    DOCUMENTS_TRANSLATION_SERVICE.get(versionId)
      .then((translations) => {
        const translationData = translations.data;
        this.isReadyToPublishHandler(translationData);
        this.setState({ translations: translationData });

        if (translationData.length > 0)
          this.translationHandler(translationData[0]);
      })
      .catch((error) => {
        this.setState({ translations: [] });
        this.setState({ selectedTranslation: null });
        this.translationHandler(null);
      });
  }

  //Checks if all translations are in status Finished,
  // which means the version is ready to be published
  isReadyToPublishHandler(translationData) {
    const readyToPublish = translationData.every(
      (item) => item.status === "FINISHED"
    );
    this.props.versionIsReadyToPublishHandler(readyToPublish);
  }

  translationHandler(selectedTranslation) {
    this.setState({ selectedTranslation: selectedTranslation });
    if (this.props.selectedTranslationHandler !== "undefined")
      this.props.selectedTranslationHandler(selectedTranslation);
  }

  newTranslationOnListHandler(newTranslation) {
    const tempTranslations = this.state.translations;
    tempTranslations.push(newTranslation);
    this.setState({
      versions: tempTranslations,
      openModal: false,
    });
  }

  windowHandler() {
    this.setState({
      openModal: !this.state.openModal,
    });
  }

  render() {
    return (
      <React.Fragment>
        <PopupWindow open={this.state.openModal} onClose={this.windowHandler}>
          <AddNewTranslationView
            onSuccess={this.newTranslationOnListHandler.bind(this)}
            onCancel={this.windowHandler}
            translations={this.state.translations}
            documentVersion={this.props.version}
          />
        </PopupWindow>

        {this.props.version && (
          <DocumentTranslation
            translations={this.state.translations}
            selectedVersion={this.props.version}
            windowHandler={this.windowHandler}
            translationSelectedHandler={this.translationHandler.bind(this)}
            selectedTranslation={this.state.selectedTranslation}
          />
        )}
      </React.Fragment>
    );
  }
}

DocumentTranslationContainer.propTypes = {
  selectedTranslationHandler: PropTypes.func,
  docId: PropTypes.object,
  checkPublish: PropTypes.bool,
};

export default DocumentTranslationContainer;
