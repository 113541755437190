import React from 'react';
import {CartesianGrid, Line, LineChart, ReferenceDot, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {PATIENT_RESULT_SERVICE} from "../../../common/services/availableServices";
import Utils from "../../../common/Utils";
import toastr from "toastr";


export default class HistoryGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentX: null,
            currentY: null,
            data: [],
        };
    }

    componentDidMount() {
        PATIENT_RESULT_SERVICE.get(this.props.patientId).then(
            values => {

                values.data.forEach(value => {
                    value.date = Utils.dateRenderer(value.date);
                });

                this.setState({data: values.data});
                this.calculateHighlight();
            }
        );
    }

    hasDuplicates(array) {
        return (new Set(array)).size !== array.length;
    }

    calculateHighlight = () => {
        const arrayIndex = this.state.data.findIndex(result => result.id === this.props.resultId);
        const hasDuplicates = this.hasDuplicates(this.state.data.map(x => x.date));
        if (arrayIndex > -1) {
            this.setState({
                currentX: hasDuplicates ? arrayIndex : this.state.data[arrayIndex]['date'],
                currentY: this.state.data[arrayIndex]['finalScore'],
            });
        } else {
            toastr.info('You are looking at an archived result that is not represented in the historic graph');
        }
    };

    render() {
        return (
            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={this.state.data} margin={{top: 5, right: 40, bottom: 75, left: 50}}>
                    <Line name="Concentration" type="monotone" dataKey="finalScore" stroke="#3B6DB1"
                          activeDot={{r: 6}}/>
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
                    <XAxis dataKey="date" angle={-50} interval={0} tick={{dx: -19, dy: 40, fontSize: 11}}/>
                    <YAxis label={{value: 'Concentration [mg/L]', angle: -90, dx: -30}}/>
                    <Tooltip formatter={value => Math.round(value * 10) / 10 + ' mg/L'}/>
                    <ReferenceDot x={this.state.currentX} y={this.state.currentY} r={5} fill="blue" stroke="none"/>
                </LineChart>
            </ResponsiveContainer>
        );
    }
}
