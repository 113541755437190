import React from "react";
import { compose } from "recompose";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import withStyles from "@material-ui/core/styles/withStyles";
import BatchDetails from "./BatchDetails";
import BatchSamplingResults from "./BatchSamplingResults";
import BatchReviewResults from "./BatchReviewResults";
import Paper from "@material-ui/core/Paper/Paper";
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
  paper: {
    marginBottom: 0,
  },
  batchDetailsTabContent: {
    margin: theme.spacing(2),
    // overflow: "hidden",
  },
});

class BatchDetailsTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: "batchDetails",
    };
  }

  handleChange = (event, value) => {
    this.setState({ selectedTab: value });
  };

  render() {
    const selectedTab = this.state.selectedTab;
    const { classes, batchNumber, onCancel, onSuccess, t } = this.props;
    const createBatch = batchNumber === null;

    return (
      <React.Fragment>
        <Paper square className={classes.paper}>
          <Tabs value={selectedTab} onChange={this.handleChange}>
            <Tab label={t("batch_details")} value="batchDetails" />
            {!createBatch && (
              <Tab label={t("sampling_results")} value="samplingResults" />
            )}
            {!createBatch && (
              <Tab label={t("review_results")} value="reviewResults" />
            )}
          </Tabs>
        </Paper>
        {selectedTab === "batchDetails" && (
          <div className={classes.batchDetailsTabContent}>
            <BatchDetails
              batchNumber={batchNumber}
              onCancel={onCancel}
              onSuccess={onSuccess}
            />
          </div>
        )}
        {selectedTab === "samplingResults" && (
          <div className={classes.batchDetailsTabContent}>
            <BatchSamplingResults
              batchNumber={batchNumber}
              onCancel={onCancel}
            />
          </div>
        )}
        {selectedTab === "reviewResults" && (
          <div className={classes.batchDetailsTabContent}>
            <BatchReviewResults batchNumber={batchNumber} onCancel={onCancel} />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), withStyles(styles))(BatchDetailsTabs);
