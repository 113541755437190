import React, { Component } from "react";
import Editor from "draft-js-plugins-editor";
import createCheckableListPlugin from "draft-js-checkable-list-plugin";
import createToolbarPlugin from "draft-js-static-toolbar-plugin";

import "./Draft.css";
import "draft-js-checkable-list-plugin/lib/plugin.css";
import "draft-js/dist/Draft.css";
import "draft-js-static-toolbar-plugin/lib/plugin.css";

import PropTypes from "prop-types";
import {
  BlockquoteButton,
  BoldButton,
  HeadlineOneButton,
  HeadlineThreeButton,
  HeadlineTwoButton,
  ItalicButton,
  OrderedListButton,
  UnderlineButton,
  UnorderedListButton,
} from "draft-js-buttons";

import withStyles from "@material-ui/core/styles/withStyles";
import ButtonBase from "@material-ui/core/ButtonBase/ButtonBase";
import Grid from "@material-ui/core/Grid/Grid";

const toolbarPlugin = createToolbarPlugin();

const checkableListPlugin = createCheckableListPlugin();
const plugins = [toolbarPlugin, checkableListPlugin];
const { Button } = checkableListPlugin;

const { Toolbar } = toolbarPlugin;

const styles = (theme) => ({
  root: {},
  toolbar: {
    marginTop: 20,
    marginBottom: 20,
  },
  mr20: {
    marginRight: 20,
  },
});

class Draft extends Component {
  constructor(props) {
    super(props);
    this.editMode =
      this.props.editMode === "undefined" ? false : this.props.editMode;

    // this.focus = () => this.refs.editor.focus();
  }

  render() {
    const classes = this.props.classes;
    return (
      <Grid container className={classes.root}>
        <Grid container className={classes.toolbar}>
          <ButtonBase className={classes.mr20}>
            <Button editorState={this.props.editorState} />
          </ButtonBase>
          <Toolbar>
            {(props) => (
              <>
                <BoldButton {...props} />
                <ItalicButton {...props} />
                <UnderlineButton {...props} />
                <UnorderedListButton {...props} />
                <OrderedListButton {...props} />
                <BlockquoteButton {...props} />
                <HeadlineOneButton {...props} />
                <HeadlineTwoButton {...props} />
                <HeadlineThreeButton {...props} />
              </>
            )}
          </Toolbar>
        </Grid>

        <Grid container>
          <Editor
            readOnly={!this.editMode}
            editorState={this.props.editorState}
            onChange={this.props.onChange}
            ref="editor"
            plugins={plugins}
          />
        </Grid>
      </Grid>
    );
  }
}

Draft.propTypes = {
  onChange: PropTypes.func.isRequired,
  editorState: PropTypes.object.isRequired,
  editMode: PropTypes.bool,
};

export default withStyles(styles)(Draft);
