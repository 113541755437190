import React from "react";
import PropTypes from "prop-types";
import SelectField from "./SelectField";
import { useTranslation } from "react-i18next";

const releaseStatuses = [
  "QC_SAMPLING",
  "QC_REVIEW",
  "QC_APPROVED",
  "RELEASED",
  "REJECTED",
].map((item) => {
  return {
    value: item,
    label: item,
  };
});

const ReleaseStatusSelectField = ({ name, ...other }) => {
  const { t } = useTranslation();

  return (
    <SelectField
      name={name}
      optionmap={releaseStatuses}
      label={t('manageBatches_labels.releaseStatus')}
      {...other}
    />
  );
};

ReleaseStatusSelectField.propTypes = {
  name: PropTypes.string.isRequired,
};


export default ReleaseStatusSelectField
