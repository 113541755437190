import React from "react";
import PagedTable from "./PagedTable";
import toastr from "toastr";

export const withTable = (Table, defaultProps) =>
  class extends React.Component {
    constructor(props) {
      super(props);
      let defaultStateProps = Object.assign(defaultProps, props);

      this.state = {
        data: [],
        page: {
          number: 0,
          size: defaultStateProps.pageSize,
          total: 0,
        },
        sorting: {
          orderBy: defaultStateProps.orderBy,
          order: defaultStateProps.order,
        },
        criteria: props.criteria,
        loading: false,
        error: false,
      };

      this.changePageHandler = this.changePageHandler.bind(this);
      this.changeRowsPerPageHandler = this.changeRowsPerPageHandler.bind(this);
      this.changeSortHandler = this.changeSortHandler.bind(this);
    }

    componentDidMount() {
      if (this.props.loadingData === true) {
        this.setState({ loading: true, error: false }, () => this.fetchData());
      }
    }

    componentDidUpdate(prevProps) {
      if (prevProps.loadingData === false && this.props.loadingData === true) {
        this.setState(
          { criteria: this.props.criteria, loading: true, error: false },
          () => this.fetchData()
        );
      }
    }

    changePageHandler(pageNumber) {
      this.setState(
        {
          loading: true,
          error: false,
          page: { ...this.state.page, number: pageNumber },
        },
        () => this.fetchData()
      );
    }

    changeRowsPerPageHandler(rowsPerPage) {
      this.setState(
        {
          loading: true,
          error: false,
          page: { ...this.state.page, size: rowsPerPage },
        },
        () => this.fetchData()
      );
    }

    changeSortHandler(orderBy) {
      if (orderBy === this.state.sorting.orderBy) {
        let order = "desc";
        if (this.state.sorting.order === "desc") order = "asc";

        this.setState(
          {
            loading: true,
            error: false,
            sorting: { ...this.state.sorting, order: order },
          },
          () => this.fetchData()
        );
      } else {
        this.setState(
          {
            loading: true,
            error: false,
            sorting: { ...this.state.sorting, orderBy: orderBy },
          },
          () => this.fetchData()
        );
      }
    }

    fetchData() {
      this.props.service
        .fetchData(this.state.page, this.state.sorting, this.state.criteria)
        .then((res) => {
          const data = res.data.content;

          if (data === undefined)
            throw new Error("Data should be placed in 'content' property");

          this.setState(
            {
              data: data,
              loading: false,
              page: { ...this.state.page, total: res.data.totalElements },
            },
            this.props.onLoadingDataFinish()
          );
        })
        .catch((error) => {
          console.error("Can't fetch data", error);
          this.setState(
            {
              data: [],
              loading: false,
              error: true,
            },
            this.props.onLoadingDataFinish()
          );

          toastr.error(this.props.errorMessage);
        });
    }

    render() {
      const { page, sorting, data, loading, error } = this.state;
      const {
        columns,
        columnLabels,
        rowKey,
        emptyResultMessage,
        errorMessage,
      } = this.props;
      return (
        <Table
          onPageChange={this.changePageHandler}
          onRowsPerPageChange={this.changeRowsPerPageHandler}
          onChangeSort={this.changeSortHandler}
          page={page}
          sorting={sorting}
          data={data}
          loading={loading}
          error={error}
          columns={columns}
          columnLabels={columnLabels}
          emptyResultMessage={emptyResultMessage}
          errorMessage={errorMessage}
          rowKey={rowKey}
          {...this.props}
        />
      );
    }
  };

withTable.defaultProps = {
  pageSize: 25,
  orderBy: "id",
  order: "asc",
};

export const PagedTableContainer = withTable(
  PagedTable,
  withTable.defaultProps
);
