import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  DISPLAY_SERVICE,
  SIGNED_AGREEMENTS_SERVICE,
} from "../common/services/availableServices";
import toastr from "toastr";
import PopupWindow from "../common/components/PopupWindow";
import AgreementList from "./AgreementList";
import AgreementDetails from "./AgreementDetails";
import { withTranslation } from "react-i18next";
class MyAgreements extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      agreements: [],
    };
  }

  componentDidMount() {
    SIGNED_AGREEMENTS_SERVICE.getAll()
      .then((response) => {
        this.setState({
          agreements: response.data,
        });
      })
      .catch((error) => {
        if (error !== undefined && error.response !== undefined)
          toastr.error(error.response.message);
      });
  }

  render() {
    const labels = this.props.t("myAgreements_labels", { returnObjects: true });
    const { openModal, agreements, content, title, version } = this.state;
    const display = content === undefined ? "" : JSON.parse(content);
    return (
      <div>
        <PopupWindow open={openModal}>
          <AgreementDetails
            handleSubmit={() =>
              this.setState({
                openModal: false,
              })
            }
            display={display}
            title={title}
            version={version}
            checkedBoxes={[]}
            editable={false}
          />
        </PopupWindow>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5" color="secondary" gutterBottom>
              {labels.title}
            </Typography>
          </Grid>
          <AgreementList
            agreements={agreements}
            showDetails={this.showDetails}
          />
        </Grid>
      </div>
    );
  }

  showDetails = (documentArrayIndex) => {
    const agreement = this.state.agreements[documentArrayIndex];
    const { documentRepresentationId } = agreement;

    DISPLAY_SERVICE.get(documentRepresentationId)
      .then((value) => {
        this.setState({
          openModal: true,
          title: agreement.documentName,
          version: agreement.documentVersionName,
          content: value.data.content,
        });
      })
      .catch((error) => {
        if (error === undefined) {
          return;
        }
        toastr.error(error.response.data.message);
      });
  };
}

export default withTranslation()(MyAgreements);
