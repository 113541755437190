import PropTypes from "prop-types";
import React from "react";
import { withStyles } from "@material-ui/core/styles/index";
import { compose } from "recompose";
import Typography from "@material-ui/core/Typography/Typography";
import Card from "@material-ui/core/Card/Card";
import MenuList from "@material-ui/core/MenuList/MenuList";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Divider from "@material-ui/core/Divider/Divider";
import { fabAddButton } from "../../common/fields/components/FabButtonComponent";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import renderIcon from "../LanguageIconsRenderer";
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  content: {
    overflow: "auto",
    maxHeight: "25vh",
  },
});

const DocumentTranslation = ({
  classes,
  translations,
  translationSelectedHandler,
  selectedTranslation,
  windowHandler,
  selectedVersion,
}) => {
  const { t } = useTranslation();
  let renderSingleItem = (translation) => {
    return (
      <div key={translation.languageCode}>
        <MenuItem
          selected={
            selectedTranslation !== null &&
            selectedTranslation.languageCode === translation.languageCode
          }
          onClick={() => translationSelectedHandler(translation)}
        >
          <ListItemIcon>
            {renderIcon(translation.status)}
          </ListItemIcon>
          <ListItemText primary={translation.languageName} />
        </MenuItem>
        <Divider />
      </div>
    );
  };

  return (
    <React.Fragment>
      <Typography variant="h5" color="secondary">
        {t('documents_section.translations.title')}
      </Typography>
      <Card className={classes.content}>
        <MenuList style={{ padding: 0 }}>
          {translations.map((item) => renderSingleItem(item))}
        </MenuList>
      </Card>
      {!selectedVersion.published &&
        fabAddButton(t('documents_section.translations.add_tooltip'), windowHandler)}
    </React.Fragment>
  );
};

DocumentTranslation.propTypes = {
  translations: PropTypes.array.isRequired,
  translationSelectedHandler: PropTypes.func.isRequired,
  selectedVersion: PropTypes.object,
};

export default compose(
  withStyles(styles),
)(DocumentTranslation);
