import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { loginRequest, logoutFailed, logoutRequest } from "../Auth/actions";
import { connect } from "react-redux";
import { compose } from "recompose";
import LandingMenu from "./LandingMenu";
import Signup from "../Signup/Signup";
import ResetPassword from "../ResetPassword/ResetPassword";
import FAQ from "../FAQ/FAQ";
import CEMark from "./CEMark";

import { withTranslation } from 'react-i18next';

const styles = () => ({
  container: {
    width: "100%",
  },
});

const MENU = "MENU";
const SIGNUP = "SIGNUP";
const RESET_PASSWORD = "RESET PASSWORD";
const FAQComponent = "FAQ";

class LandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      view: MENU,
    };
  }

  render() {
    const { classes, t } = this.props;
    return (
      <div className={classes.container}>
        {this.state.view === MENU && (
          <LandingMenu
            onSignup={() => this.setState({ view: SIGNUP })}
            onResetPassword={() => this.setState({ view: RESET_PASSWORD })}
            onFAQShow={() => this.setState({ view: FAQComponent })}
          />
        )}
        {this.state.view === SIGNUP && (
          <Signup onHome={() => this.setState({ view: MENU })} />
        )}
        {this.state.view === RESET_PASSWORD && (
          <ResetPassword onHome={() => this.setState({ view: MENU })} />
        )}
        {this.state.view === FAQComponent && <FAQ onHome={() => this.setState({ view: MENU })} />}
        <CEMark />
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return auth;
}

export default compose(
  withStyles(styles),
  withTranslation(),
  connect(
    mapStateToProps,
    {
      loginRequest,
      logoutRequest,
      logoutFailed,
    }
  )
)(LandingPage);
