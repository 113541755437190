import React from "react";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  input: {
    display: "flex",
    padding: 0,
  },
  valueContainer: {
    flex: 1,
    alignItems: "center",
    paddingTop: 6,
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    position: "absolute",
    fontSize: 16,
    left: 2,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: "absolute",
    zIndex: 100000,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.children}
    >
      {props.selectProps.notfound}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputLabelProps={{
        children: props.selectProps.classes.noOptionsMessage,
      }}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        marginTop: 10,
        fontWeight: props.isSelected ? 500 : 400,
        whiteSpace: "normal",
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      style={{ color: props.isDisabled ? "#b9b9b9" : "black" }}
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const AutocompleteSelectField = ({
  classes,
  theme,
  name,
  optionmap,
  placeholder,
  disabled,
  label,
  onMenuOpen,
  onMenuClose,
  ...other
}) => {
  const { t } = useTranslation();
  return (
    <div style={{ marginTop: 1 }}>
      <Select
        classes={classes}
        backspaceRemoves={true}
        deleteRemoves={true}
        options={[{ value: "", label: "" }, ...optionmap]}
        components={components}
        placeholder={placeholder}
        isDisabled={disabled}
        onChange={(value) => {
          let possiblePlaceholder = value;
          if (Array.isArray(value) && value.length === 0) {
            possiblePlaceholder = {
              value: "",
              label: "",
            };
          }
          other.onChange(possiblePlaceholder);
        }}
        value={other.value}
        notfound={t('select_field.no_option')}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
      />
    </div>
  );
};

AutocompleteSelectField.defaultProps = {
  optionmap: [{ value: "", label: "" }],
};

export default withStyles(styles, { withTheme: true })(AutocompleteSelectField);
