import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { reduxForm } from "redux-form";
import { PagedTableContainer } from "../common/components/PagedTable/PagedTableContainer";
import {
  EXPORT_PATIENTS_SERVICE,
  PATIENT_SERVICE,
} from "../common/services/availableServices";
import { compose } from "recompose";
import { columnType } from "../common/components/PagedTable/columnType";
import SearchTextField from "../common/fields/SearchTextField";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import PermissionContainer from "../Auth/PermissionContainer";
import { permission } from "../Auth/permissions";
import { connect } from "react-redux";
import ActiveSelectField from "../common/fields/ActiveSelectField";
import PopupWindow from "../common/components/PopupWindow";
import PatientDetails from "./PatientDetails";
import { managePageStyles } from "../styles";
import ClinicsSelectField from "../common/fields/selectFields/ClinicsSelectField";
import PatientTypeSelectField from "../common/fields/selectFields/PatientTypeSelectField";
import ExportExcelButton from "../common/components/ExportExcelButton";
import { withTranslation } from 'react-i18next';

class ManagePatients extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      criteria: null,
      openModal: false,
      patientId: null,
      loadingTableData: true,
    };
    this.tableColumnSetup();
  }

  tableColumnSetup() {
    this.columns = [
      { key: "id", type: columnType.numeric },
      { key: "externalId", type: columnType.text },
      { key: "email", type: columnType.text, sortable: false },
      { key: "firstName", type: columnType.text },
      { key: "lastName", type: columnType.text },
      {
        key: "treatmentType",
        type: columnType.text,
        customCellRenderer: this.treatmentTypeCellRenderer,
        sortable: false,
      },
      { key: "created", type: columnType.text },
      {
        key: "active",
        type: columnType.text,
        customCellRenderer: this.activeCellRenderer,
        sortable: false,
      },
    ];

    if (
      PermissionContainer.checkAllowedPermission(
        permission.patient_view_all,
        this.props.permissions
      )
    ) {
      let clinicColumn = {
        key: "clinicName",
        type: columnType.text,
        orderProperty: "employee.clinic.name",
      };
      this.columns.push(clinicColumn);
    }

    let editColumn = {
      key: "details",
      type: columnType.text,
      customCellRenderer: this.detailsCellRenderer,
      sortable: false,
    };
    this.columns.push(editColumn);
  }

  activeCellRenderer = (rowKey, columnValue) => {
    if (columnValue !== undefined)
      if (columnValue === true) return this.props.t('statuses.active');
      else if (columnValue === false)
        return this.props.t('statuses.inactive');
  };

  treatmentTypeCellRenderer = (rowKey, columnValue) => {
    if (columnValue !== undefined)
      if (columnValue === "HOME")
        return this.props.t('managePatients_labels.patient_types.home');
      else if (columnValue === "LAB")
        return this.props.t('managePatients_labels.patient_types.lab');
  };

  detailsCellRenderer = (rowId) => {
    return (
      <Button onClick={this.detailsButtonClickHandler(rowId)}>
        {this.props.t('buttons.details')}
      </Button>
    );
  };

  detailsButtonClickHandler = (rowId) => () => {
    this.setState({
      openModal: true,
      patientId: rowId,
    });
  };
  createButtonClickHandler = () => {
    this.setState({
      openModal: true,
      patientId: NaN,
    });
  };

  searchButtonClickHandler = (values) => {
    let queryParams = {
      search: values.search,
    };

    if ("treatmentType" in values) {
      queryParams = {
        ...queryParams,
        treatmentType: values.treatmentType,
      };
    }
    if ("active" in values) {
      let isActiveSet =
        values.active === this.props.t('statuses.active').toLowerCase();
      queryParams = {
        ...queryParams,
        active: isActiveSet,
      };
    }

    if ("clinic" in values) {
      queryParams = {
        ...queryParams,
        "employee.clinic.id": values.clinic,
      };
    }

    this.setState({
      criteria: queryParams,
      loadingTableData: true,
    });
  };

  closeWindowHandler = () => {
    this.setState({ patientId: null, openModal: false });
  };
  successHandler = () => {
    this.setState({
      patientId: null,
      openModal: false,
      loadingTableData: true,
    });
  };

  loadingDataFinishHandler = () => {
    this.setState({
      loadingTableData: false,
    });
  };

  render() {
    const { handleSubmit, classes, t } = this.props;
    const { criteria, patientId, openModal, loadingTableData } = this.state;

    return (
      <div>
        <PopupWindow open={openModal}>
          <PatientDetails
            patientId={patientId}
            onCancel={this.closeWindowHandler}
            onSuccess={this.successHandler}
            style={{ height: "72vh" }}
          />
        </PopupWindow>
        <Grid container className={classes.header}>
          <Grid item xs={4}>
            <Typography variant="h5" color="secondary">
              {t('managePatients_labels.page_title')}
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid item>
                <ExportExcelButton
                  service={EXPORT_PATIENTS_SERVICE}
                  criteria={this.state.criteria}
                />
              </Grid>
              <Grid item>
                <PermissionContainer
                  allowedPermissions={[
                    permission.patient_add_all,
                    permission.patient_add_clinic,
                    permission.patient_add_your,
                  ]}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    onClick={this.createButtonClickHandler}
                  >
                    {t('managePatients_labels.create_patient_button')}
                  </Button>
                </PermissionContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <form onSubmit={handleSubmit(this.searchButtonClickHandler)}>
          <Grid spacing={2} container className={classes.criteria}>
            <Grid item md={3}>
              <SearchTextField
                name="search"
                className={classes.criteriaField}
              />
            </Grid>
            <Grid item md={3}>
              <div style={{ marginTop: 11 }}>
                <PatientTypeSelectField
                  name="treatmentType"
                  className={classes.criteriaField}
                />
              </div>
            </Grid>
            <Grid item md={3}>
              <div style={{ marginTop: 11 }}>
                <ActiveSelectField
                  name="active"
                  className={classes.criteriaField}
                />
              </div>
            </Grid>
            <Grid item md={2}>
              <div style={{ marginTop: 6 }}>
                <PermissionContainer
                  allowedPermissions={[permission.patient_view_all]}
                >
                  <ClinicsSelectField
                    name="clinic"
                    className={classes.criteriaField}
                  />
                </PermissionContainer>
              </div>
            </Grid>
            <Grid
              item
              container
              md={1}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button variant="contained" color="primary" type="submit">
                {t('buttons.search')}
              </Button>
            </Grid>
          </Grid>
        </form>
        <PagedTableContainer
          rowKey="id"
          orderBy="lastName"
          order="asc"
          columns={this.columns}
          criteria={criteria}
          service={PATIENT_SERVICE}
          loadingData={loadingTableData}
          onLoadingDataFinish={this.loadingDataFinishHandler}
          errorMessage={t('table.error_message')}
          emptyResultMessage={t('table.empty_result')}
          columnLabels={t('managePatients_labels.columns', { returnObjects: true })}
        />
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return { permissions: auth.user.permissions };
}

export default compose(
  withStyles(managePageStyles),
  connect(mapStateToProps, null),
  withTranslation(),
  reduxForm({ form: "PatientSearch" }),
)(ManagePatients);
