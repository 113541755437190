import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ResetLinkSent = ({ onHome }) => {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6" gutterBottom>
          {t("reset_password.success_title")}
        </Typography>
        <Typography>{t("reset_password.success")}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Grid container justifyContent={"flex-end"}>
          <Button
            variant="contained"
            color="primary"
            onClick={onHome}
            component={Link}
            to={"/"}
          >
            {t("buttons.home")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResetLinkSent;
