import { createTheme } from '@material-ui/core/styles'

export const primary = '#001e45';
export const secondary = '#EC492B';
const paragrafColor = '#464'
const surface = '#ffffff';
const surfaceDark = '#f9f9f9';

const drawerWidth = 240;

export const styles = createTheme({
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    surface: {
      main: surface,
      dark: surfaceDark,
    },
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiAppBar: {
      root: {
        marginBottom: "1px",
      },
    },
    MuiToolbar: {
      root: {
        backgroundColor: surface,
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: surfaceDark,
        position: "relative",
        width: drawerWidth,
      },
    },
    MuiButton: {
      root: {
        color: primary,
        minWidth: 83,
      },
    },
    MuiCheckbox: {
      root: {
        color: secondary,
        '&$checked':{
          color: secondary,
        }
      },
    },
  },
});

export const managePageStyles = (theme) => ({
  header: {
    paddingBottom: theme.spacing(4),
  },
  criteria: {
    paddingBottom: theme.spacing(4),
  },
  criteriaField: {
    width: "100%",
  },
});
