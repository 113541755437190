import React, { Component } from "react";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import TextField from "../common/fields/TextField";
import { compose } from "redux";
import { withStyles } from "@material-ui/core";
import { getFormValues, initialize, reduxForm } from "redux-form";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button/Button";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Barcode from "../common/components/Barcode";
import CheckBoxField from "../common/fields/CheckBoxField";
import toastr from "toastr";
import { BATCH_SERVICE } from "../common/services/availableServices";
import PropTypes from "prop-types";
import { number, required } from "../common/fieldValidators";
import ReleaseStatusComponent from "./ReleaseStatusComponent";
import PopupWindow from "../common/components/PopupWindow";
import RejectBatch from "./RejectBatch";
import Utils from "../common/Utils";
import { constructErrorMessage, FormMode } from "../common/FormUtils";
import PermissionContainer from "../Auth/PermissionContainer";
import { permission as permissions } from "../Auth/permissions";
import FormButtons from "../common/components/FormButtons";
import LoadingIndicator from "../common/components/LoadingIndicator";
import { withTranslation } from 'react-i18next';

const Latex = require("react-latex");

const reduxFormName = "createBatch";

const styles = (theme) => ({
  card: {
    minHeight: 100,
  },
  inputField: {
    marginRight: 0,
  },
  buttonMargin: {
    marginRight: 32,
  },
  formRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    alignContent: "flex-start",
  },
  statusHistory: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "flex-start",
    marginRight: 100,
  },
  releaseStatus: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "flex-start",
    alignContent: "flex-start",
    marginRight: 100,
    marginBottom: 2 * theme.spacing(1),
  },
  expiryDate: {
    flexGrow: 1,
    marginLeft: 16,
  },
  manufacture_date: {
    flexGrow: 1,
    marginRight: 16,
  },
});

class BatchDetails extends Component {
  successHandler = (values) => {
    this.updateBatch(values, this.state.batchNumber);
    this.closeWindowHandler();
  };

  closeWindowHandler = () => {
    this.setState({
      openRejectPopup: false,
    });
  };

  constructor(props) {
    super(props);

    this.state = {
      openRejectPopup: false,
      batchNumber: this.props.batchNumber,
      batch: null,
      formMode: BatchDetails.initializeFormMode(this.props.batchNumber),
      loading: false,
      isModified: false,
    };
    this.editButtonClickHandler = this.editButtonClickHandler.bind(this);
    this.cancelButtonClickHandler = this.cancelButtonClickHandler.bind(this);
    this.closeButtonClickHandler = this.closeButtonClickHandler.bind(this);
    this.editable = this.editable.bind(this);
    this.updateBatch = this.updateBatch.bind(this);
  }

  static initializeFormMode(batchNumber) {
    if (batchNumber !== null) return FormMode.VIEW;
    else return FormMode.CREATE;
  }

  componentDidMount() {
    if (FormMode.VIEW === this.state.formMode) {
      this.fetchBatch(this.state.batchNumber);
    } else if (FormMode.CREATE === this.state.formMode) {
      this.props.dispatch(
        initialize(reduxFormName, {
          createdBy:
            this.props.auth.user.name + " " + this.props.auth.user.surname,
        })
      );
    }
  }

  closeButtonClickHandler() {
    if (this.state.isModified) {
      this.props.onSuccess();
    } else {
      this.props.onCancel();
    }
  }

  cancelButtonClickHandler() {
    this.setState(
      {
        formMode: FormMode.VIEW,
      },
      () => this.fetchBatch(this.state.batchNumber)
    );
  }

  editButtonClickHandler() {
    this.setState({
      formMode: FormMode.EDIT,
    });
  }

  submitFormHandler(values) {
    if (FormMode.CREATE === this.state.formMode) {
      this.createBatch(values);
    } else if (FormMode.EDIT === this.state.formMode) {
      this.updateBatch(values, this.state.batchNumber);
    }
  }

  fetchBatch(batchNumber) {
    this.setState({
      loading: true,
    });

    BATCH_SERVICE.get(batchNumber)
      .then((response) => {
        this.props.dispatch(initialize(reduxFormName, response.data));
        this.setState({
          batch: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        if (!error.handledGlobally)
          toastr.error(constructErrorMessage("Can't fetch batch.", error));
      });
  }

  createBatch(values) {
    this.setState({
      loading: true,
    });

    BATCH_SERVICE.post(values)
      .then((response) => {
        this.setState({
          loading: false,
          batchNumber: response.data.number,
          batch: response.data,
          isModified: true,
          formMode: FormMode.VIEW,
        });

        this.props.dispatch(initialize(reduxFormName, response.data));

        toastr.success(this.props.t('create_batch.batch_created'));
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });

        if (!error.handledGlobally)
          toastr.error(
            constructErrorMessage(this.props.t('create_batch.batch_failed'), error)
          );
      });
  }

  updateBatch(values, batchNumber) {
    this.setState({
      loading: true,
    });

    BATCH_SERVICE.put(values, batchNumber)
      .then((response) => {
        this.setState({
          loading: false,
          isModified: true,
          batch: response.data,
          formMode: FormMode.VIEW,
        });

        this.props.dispatch(initialize(reduxFormName, response.data));
        toastr.success(this.props.t('create_batch.batch_updated'));
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });

        if (!error.handledGlobally)
          toastr.error(
            constructErrorMessage(this.props.t('create_batch.batch_failed'), error)
          );
      });
  }

  rejectBatch(batchNumber) {}

  rejectHandler() {
    this.setState({
      openRejectPopup: true,
    });
  }

  editable() {
    return PermissionContainer.checkAllowedPermissions(
      [permissions.batches_edit],
      this.props.auth.user.permissions
    );
  }

  render() {
    const { handleSubmit, classes, formValues, t } = this.props;

    const { formMode, loading, batch } = this.state;

    const disabled =
      formMode === FormMode.VIEW || (batch && batch.status === "REJECTED");

    return (
      <React.Fragment>
        {loading && <LoadingIndicator />}
        <PopupWindow
          open={this.state.openRejectPopup}
          onClose={this.closeWindowHandler}
        >
          {
            <RejectBatch
              batchNumber={this.state.batchNumber}
              onSuccess={this.successHandler}
              onCancel={this.closeWindowHandler}
              formValues={formValues}
            />
          }
        </PopupWindow>
        <div style={{ padding: 16 }}>
          <form
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(this.submitFormHandler.bind(this))}
          >
            <Grid
              container
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography variant="h5" color="secondary">
                  {formMode === FormMode.CREATE
                    ? t('create_batch.create_batch')
                    : t('create_batch.batch_details')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormGroup>
                  <TextField
                    name={"createdBy"}
                    label={t('create_batch.created_by')}
                    className={classes.inputField}
                    disabled={true}
                  />
                  <TextField
                    type="number"
                    name={"number"}
                    label={t('create_batch.batch_number')}
                    className={classes.inputField}
                    required
                    validate={[required]}
                    disabled={FormMode.CREATE !== formMode}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormGroup className={classes.container}>
                  <div className={classes.releaseStatus}>
                    <div>
                      <Typography
                        variant={"caption"}
                        style={{ marginBottom: 16 }}
                      >
                        {t('create_batch.release_status')}
                      </Typography>
                      <ReleaseStatusComponent
                        releaseStatus={batch ? batch.status : null}
                      />
                    </div>
                    <div style={{ marginLeft: 32 }}>
                      <Typography
                        variant={"caption"}
                        style={{ marginBottom: 16 }}
                      >
                        {t('barcode')}
                      </Typography>
                      <Card className={classes.card}>
                        <CardContent style={{ padding: 16 }}>
                          <Barcode batchId={this.state.batchNumber} />
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                  <div>
                    <Typography
                      variant={"caption"}
                      style={{ marginBottom: 16 }}
                    >
                      {t('create_batch.calibration_equation')}
                    </Typography>
                    <Latex> $Ax^2 + Bx + C $</Latex>
                  </div>
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormGroup>
                  <div className={classes.formRow}>
                    <TextField
                      name={"creationDate"}
                      label={t('create_batch.manufacture_date')}
                      className={classes.manufacture_date}
                      type="date"
                      required
                      validate={[required]}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={disabled}
                    />
                    <TextField
                      name={"expirationDate"}
                      label={t('create_batch.expiry_date')}
                      className={classes.expiryDate}
                      type="date"
                      required
                      validate={[required]}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={disabled}
                    />
                  </div>
                  <TextField
                    name={"lowerLimit"}
                    label={t('create_batch.moderate_threshold')}
                    className={classes.inputField}
                    required
                    validate={[required, number]}
                    disabled={disabled}
                  />
                  <TextField
                    name={"upperLimit"}
                    label={t('create_batch.severe_threshold')}
                    className={classes.inputField}
                    required
                    validate={[required, number]}
                    disabled={disabled}
                  />
                  {batch && batch.status === "REJECTED" && (
                    <TextField
                      name={"notes"}
                      multiline
                      label={"Notes"}
                      disabled={true}
                    />
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormGroup>
                  <TextField
                    name={"calibrationParameter1"}
                    label={t('create_batch.calibration_parameter_a')}
                    className={classes.inputField}
                    disabled={disabled}
                    type="number"
                    validate={
                      formValues && formValues["reviewed"]
                        ? [required, number]
                        : null
                    }
                  />
                  <TextField
                    name={"calibrationParameter2"}
                    label={t('create_batch.calibration_parameter_b')}
                    className={classes.inputField}
                    disabled={disabled}
                    type="number"
                    validate={
                      formValues && formValues["reviewed"]
                        ? [required, number]
                        : null
                    }
                  />
                  <TextField
                    name={"calibrationParameter3"}
                    label={t('create_batch.calibration_parameter_c')}
                    className={classes.inputField}
                    disabled={disabled}
                    type="number"
                    validate={
                      formValues && formValues["reviewed"]
                        ? [required, number]
                        : null
                    }
                  />

                  {batch && (
                    <div>
                      <div className={classes.statusHistory} style={{width: "100%"}}>
                        <CheckBoxField
                          name={"reviewed"}
                          label={t('QC_review')}
                          style={{ marginTop: 8, width: "25%" }}
                          disabled={disabled}
                          />
                        {batch && batch.reviewedDate && (
                          <Typography variant={"caption"} style={{  width: "75%" }}>
                            on {Utils.dateRenderer(batch.reviewedDate)} by{" "}
                            {batch.reviewedUser}
                          </Typography>
                        )}
                      </div>
                      {batch.reviewedDate && (
                        <div className={classes.statusHistory} style={{width: "100%"}}>
                          <CheckBoxField
                          name={"approved"}
                            label={t("QC_approved")}
                            style={{ marginTop: 8, width: "25%" }}
                            disabled={disabled}
                            />
                          {batch.approvedDate && (
                            <Typography variant={"caption"} style={{  width: "75%" }}>
                              on {Utils.dateRenderer(batch.approvedDate)} by{" "}
                              {batch.approvedUser}
                            </Typography>
                          )}
                        </div>
                      )}
                      {batch.approvedDate && (
                        <div className={classes.statusHistory} style={{width: "100%"}}>
                          <CheckBoxField
                            name={"released"}
                            label={t("Released")}
                            style={{ marginTop: 8, width: "25%" }}
                            disabled={disabled}
                            />
                          {batch.releasedDate && (
                            <Typography variant={"caption"} style={{  width: "75%" }}>
                              on {Utils.dateRenderer(batch.releasedDate)} by{" "}
                              {batch.releasedUser}
                            </Typography>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </FormGroup>
              </Grid>
            </Grid>

            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={1}
              style={{ marginTop: 10 }}
            >
              <Grid item>
                {batch && batch.status !== "REJECTED" && (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.buttonMargin}
                      onClick={this.rejectHandler.bind(this)}
                    >
                      {t('create_batch.reject')}
                    </Button>
                  </Grid>
                )}
              </Grid>
              <FormButtons
                formMode={formMode}
                onCloseButtonClick={this.closeButtonClickHandler}
                onCancelButtonClick={this.cancelButtonClickHandler}
                onEditButtonClick={this.editButtonClickHandler}
                actionButtonDisabled={loading}
                editModeEnabled={this.editable()}
              />
            </Grid>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

BatchDetails.propTypes = {
  batchNumber: PropTypes.string,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  batch: PropTypes.shape({
    status: PropTypes.string,
    releasedDate: PropTypes.string,
    releasedUser: PropTypes.string,
    approvedDate: PropTypes.string,
    reviewedDate: PropTypes.string,
    approvedUser: PropTypes.string,
    reviewedUser: PropTypes.string,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues(reduxFormName)(state),
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  reduxForm({
    form: reduxFormName,
  }),
  connect(mapStateToProps),
  withTranslation()
)(BatchDetails);
