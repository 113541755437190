export const renderFinalScore = (finalConcentration, prefix, finalScore) => {
  if (finalScore < 14) {
    return '<14';
  }
  if (finalScore >= 14 && finalScore < 24) {
    return `${prefix} 14 and 24`;
  }
  if (finalScore >= 24 && finalScore < 300) {
    return finalScore;
  }
  if (finalScore >= 300) {
    return '>300';
  }

  return finalConcentration;
}