import React, { Component } from "react";
import BwipJs from "bwip-js";

class Barcode extends Component {
  componentDidUpdate() {
    const { batchId } = this.props;
    BwipJs.toCanvas(
      "mycanvas",
      {
        bcid: "datamatrix", // Barcode type
        text: batchId ? batchId : " ", // Text to encode !!TODO!! this is requiered but theres no values here, on test.calprop also.. but there due to old lib version theres no critical error
        scale: 5, // 5x scaling factor
        textxalign: "center", // Always good to set this
      },
      function (err, cvs) {
        if (err) {
          console.log(err);
        } else {
          // Nothing else to do in this example...
        }
      }
    );
  }

  render() {
    return <canvas id="mycanvas"></canvas>;
  }
}

export default Barcode;
