import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose } from "recompose";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FAQContactForm from "./FAQContactForm";

import Divider from "@material-ui/core/Divider/Divider";
import { useTranslation } from "react-i18next";

const styles = () => ({
  logForm: {
    width: "450px",
    display: "inline-block",
    textAlign: "left",
  },
  mt60: {
    marginTop: 60,
  },
});

const FAQ = ({ onHome }) => {
  const [t] = useTranslation();

  const questions = t('faq.questionsAndAsnswers', { returnObjects: true });

  const MyAccordionArray = questions.map((item, i) => {
    return (
      <Accordion key={i}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">{item.question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <p>{item.answer}</p>
        </AccordionDetails>
      </Accordion>
    );
  });

  return (
    <div style={{ marginTop: 60, overflow: 'hidden' }}>
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="center"
        className={styles.mt60}
        style={{ maxWidth: "100%" }}
      >
        <Grid item xs={8}>
          <Typography variant="h5" color="primary" align="center">
            {t("faq.title")}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          {MyAccordionArray.map((accordion) => accordion)}
        </Grid>
        <Grid item xs={8}>
          <Button
            variant="outlined"
            color="primary"
            onClick={onHome}
            component={Link}
            to={"/"}
          >
            {t("buttons.home")}
          </Button>
        </Grid>
        <Grid item xs={8}>
          <Divider />
        </Grid>
        <Grid item xs={8}>
          <FAQContactForm />
        </Grid>
      </Grid>
    </div>
  );
};

const mapTranslationsToProps = ({ labels }) => ({ labels });

export default compose(
  withStyles(styles),
)(FAQ);
