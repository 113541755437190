import React from "react";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

const PageNotFound = () => {
  const { t, } = useTranslation();

  return (
    <Typography variant="h5" color="secondary">
      {t('page_not_found')}
    </Typography>
  );
};

export default PageNotFound
