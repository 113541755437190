import React from "react";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SelectFieldComponent = ({
  input,
  label,
  name,
  meta: { touched, error },
  children,
  className,
  required,
  optionmap,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <FormControl
      error={error && touched}
      className={className}
      required={required}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select
        label={label}
        {...input}
        onChange={(event) => input.onChange(event)}
        children={children}
        error={error && touched}
        name={name}
        optionmap={optionmap}
        MenuProps={MenuProps}
        {...props}
      />

      <FormHelperText error={touched && error !== undefined}>
        {touched && error} {!(touched && error) && required && t("required")}
      </FormHelperText>
    </FormControl>
  );
};

export default SelectFieldComponent
