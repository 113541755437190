import FileDownloaderService from "../services/FileDownloaderService";
import toastr from "toastr";
import Button from "@material-ui/core/Button/Button";
import React from "react";
import PropTypes from "prop-types";
import RestService from "../services/RestService";
import LoadingIndicator from "./LoadingIndicator";
import { constructErrorMessage } from "../FormUtils";
import { withTranslation } from "react-i18next";

class ExportExcelButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const { service, criteria, t } = this.props;

    const namedExportService = this.exportResultService(service, criteria);
    return (
      <React.Fragment>
        {this.state.loading && <LoadingIndicator />}
        <Button
          disabled={this.state.loading}
          variant="outlined"
          color="primary"
          onClick={namedExportService}
        >
          {t('buttons.export_results')}
        </Button>
      </React.Fragment>
    );
  }

  exportResultService = (service, criteria) => () => {
    this.setState({ loading: true });
    service
      .getBlobWithParams(criteria)
      .then((response) => {
        FileDownloaderService.handleBlobResponse(response);
      })
      .catch((error) => {
        console.error(error);
        if (!error.handledGlobally)
          toastr.error(constructErrorMessage("Can't export data.", error));
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };
}

ExportExcelButton.propTypes = {
  criteria: PropTypes.any,
  service: PropTypes.instanceOf(RestService),
};

export default withTranslation()(ExportExcelButton)
