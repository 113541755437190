import React, { Component } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import UserForm from "./UserForm";
import ClinicForm from "./ClinicForm";
import ConfirmInfo from "./ConfirmInfo";
import Done from "./Done";
import { SIGNUP_SERVICE } from "../common/services/availableServices";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import toastr from "toastr";
import { withTranslation } from "react-i18next";

const styles = () => ({
  container: {
    justifyContent: "center",
    minWidth: "600px",
  },
  logForm: {
    minWidth: "500px",
    maxWidth: "500px",
    padding: "50px",
  },
});

class Signup extends Component {
  state = {
    activeStep: 0,
  };

  getSteps = () => {
    return [
      this.props.t("signup_labels.user_tab"),
      this.props.t("signup_labels.clinic_tab"),
      this.props.t("signup_labels.summary_tab"),
    ];
  };

  getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <UserForm onSubmit={this.handleNext} onHome={this.props.onHome} />
        );
      case 1:
        return (
          <ClinicForm onSubmit={this.handleNext} handleBack={this.handleBack} />
        );
      case 2:
        return (
          <ConfirmInfo
            onSubmit={this.submitHandler}
            handleBack={this.handleBack}
          />
        );
      case 3:
        return <Done onHome={this.props.onHome} />;
      default:
        return "Unknown step";
    }
  };

  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  submitHandler = (values) => {
    SIGNUP_SERVICE.post(values)
      .then(() => {
        this.handleNext();
      })
      .catch((error) => {
        console.error(error);
        if (typeof error.response === "undefined") {
          toastr.error("Internal server error");
        } else {
          toastr.error(this.props.t("signup_labels.error_message"));
        }
      });
  };

  render() {
    const steps = this.getSteps();
    const { activeStep } = this.state;
    const { classes } = this.props;

    return (
      <Grid container className={classes.container}>
        <Paper className={classes.logForm}>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {this.getStepContent(activeStep)}
        </Paper>
      </Grid>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
  reduxForm({
    form: "UserSignupForm",
  })
)(Signup);
