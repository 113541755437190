import React from "react";
import ServiceSelectField from "./ServiceSelectField";
import PropTypes from "prop-types";
import { LANGUAGES_SERVICE } from "../../services/availableServices";
import { useTranslation } from 'react-i18next';

const LanguageSelectField = ({ label, name, removeAll, ...other }) => {

  const { t } = useTranslation();

  const customMapperHandler = (values) => {
    let formattedValues = values.map((item) => {
      return {
        value: item.code,
        label: item.name,
      };
    });
    const removeAllCodes = removeAll.map((item) => item.languageCode);

    formattedValues = formattedValues.filter((item) => {
      return !removeAllCodes.includes(item.value);
    });

    return formattedValues;
  };

  return (
    <ServiceSelectField
      name={name}
      service={LANGUAGES_SERVICE}
      customMapper={customMapperHandler}
      label={t('select_field.language')}
      {...other}
    />
  );
};

LanguageSelectField.propTypes = {
  name: PropTypes.string.isRequired,
};

LanguageSelectField.defaultProps = {
  removeAll: [],
};

export default LanguageSelectField
