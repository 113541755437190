import React, { Component } from "react";
import Typography from "@material-ui/core/Typography/Typography";
import TextField from "../common/fields/TextField";
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import Grid from "@material-ui/core/Grid/Grid";
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import { reduxForm } from "redux-form";
import { compose } from "redux";
import { required } from "../common/fieldValidators";
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  header: {
    color: theme.palette.secondary,
  },
});

class RejectBatch extends Component {
  submitHandler(values) {
    let newValues = Object.assign({}, this.props.formValues, values, {
      rejected: true,
    });
    this.props.onSuccess(newValues);
  }

  render() {
    const { handleSubmit, classes, t } = this.props;
    const translatedLabels = t('create_batch', { returnObjects: true } );
    return (
      <form noValidate onSubmit={handleSubmit(this.submitHandler.bind(this))}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.header}>
              {translatedLabels.reject_batch} {this.props.batchNumber}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {translatedLabels.reject_batch_line1}
              <br />
              {translatedLabels.reject_batch_line2}
              <br />
              {translatedLabels.reject_batch_line3}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <TextField
                name={"notes"}
                label={translatedLabels.reason_label}
                rows={5}
                multiline={true}
                validate={[required]}
                required
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              className={classes.buttonMargin}
              onClick={this.props.onCancel}
            >
              {t('buttons.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.buttonMargin}
            >
              {t('buttons.confirm')}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

RejectBatch.propTypes = {
  batchNumber: PropTypes.string,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default compose(
  withStyles(styles),
  reduxForm({
    form: "rejectBatch",
  }),
  withTranslation(),
)(RejectBatch);
