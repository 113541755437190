import React from "react";
import Chip from "@material-ui/core/Chip/Chip";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = (theme) => ({
  label: {
    color: "black",
  },
});

const releaseStatusColors = {
  QC_SAMPLING: "#c7c7c7",
  QC_REVIEW: "#fbdb60",
  QC_APPROVED: "#ffbe6b",
  RELEASED: "#6dde69",
  REJECTED: "#ff7975",
};

const ReleaseStatusComponent = ({ releaseStatus, classes }) => {
  const inlineStyle = {
    background: releaseStatusColors[releaseStatus],
  };

  return (
    <Chip
      label={releaseStatus}
      style={inlineStyle}
      classes={{ label: classes.label }}
    />
  );
};
export default withStyles(styles)(ReleaseStatusComponent);
