import PropTypes from "prop-types";
import React from "react";
import { withStyles } from "@material-ui/core/styles/index";
import Typography from "@material-ui/core/Typography/Typography";
import Grid from "@material-ui/core/Grid/Grid";
import Paper from "@material-ui/core/Paper/Paper";
import Button from "@material-ui/core/Button/Button";
import renderAgreement from "./renderAgreement";
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  content: {
    overflow: "auto",
  },
});

const DocumentRepresentation = ({
  docText,
  translation,
  version,
  windowHandler,
}) => {
  if (translation === null) return <div />;

  const { t } = useTranslation();

  return (
    <Paper>
      <Grid container style={{ padding: 20 }}>
        <Grid item xs={8}>
          <Typography variant="h5" color="secondary">
            {translation.languageName}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item>
              {!version.published && (
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  onClick={windowHandler}
                >
                  {t('documents_section.document_representation.edit')}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div
        style={{ overflowY: "scroll", padding: "15px", maxHeight: "80vh" }}
        dir={translation.textDirection}
      >
        {docText !== null &&
          docText !== "undefined" &&
          docText !== "" &&
          renderAgreement(docText)}
      </div>
    </Paper>
  );
};

DocumentRepresentation.propTypes = {
  docText: PropTypes.string,
  translation: PropTypes.object,
  version: PropTypes.object,
};

export default withStyles(styles)(DocumentRepresentation);
