import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { compose } from "recompose";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { required } from "../common/fieldValidators";
import Captcha from "./captcha";
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";

const styles = () => ({
  finishButtonContainer: {
    float: "right",
  },
  prevButtonContainer: {
    float: "left",
  },
  formContainer: {
    minHeight: "650px",
    maxHeight: "650px",
  },
});

class ConfirmInfo extends React.Component {
  render() {
    const { form_data, handleBack, handleSubmit, classes, t } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1} className={classes.formContainer}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {t("signup_labels.confirm_info")}
            </Typography>
          </Grid>
          <Grid className="summary-grid" item xs={6}>
            <Typography variant="body1">
              {t("signup_labels.first_name")}:
            </Typography>
          </Grid>
          <Grid className="summary-grid" item xs={6}>
            <Typography variant="body1">{form_data.first_name}</Typography>
          </Grid>
          <Grid className="summary-grid" item xs={6}>
            <Typography variant="body1">
              {t("signup_labels.last_name")}:
            </Typography>
          </Grid>
          <Grid className="summary-grid" item xs={6}>
            <Typography variant="body1">{form_data.last_name}</Typography>
          </Grid>
          <Grid className="summary-grid" item xs={6}>
            <Typography variant="body1">{t("signup_labels.email")}:</Typography>
          </Grid>
          <Grid className="summary-grid" item xs={6}>
            <Typography variant="body1">{form_data.email}</Typography>
          </Grid>
          <Grid className="summary-grid" item xs={6}>
            <Typography variant="body1">
              {t("signup_labels.company")}:
            </Typography>
          </Grid>
          <Grid className="summary-grid" item xs={6}>
            <Typography variant="body1">{form_data.company}</Typography>
          </Grid>
          <Grid className="summary-grid" item xs={6}>
            <Typography variant="body1">
              {t("signup_labels.address1")}:
            </Typography>
          </Grid>
          <Grid className="summary-grid" item xs={6}>
            <Typography variant="body1">{form_data.address1}</Typography>
          </Grid>
          <Grid className="summary-grid" item xs={6}>
            <Typography variant="body1">
              {t("signup_labels.address2")}:
            </Typography>
          </Grid>
          <Grid className="summary-grid" item xs={6}>
            <Typography variant="body1">{form_data.address2}</Typography>
          </Grid>
          <Grid className="summary-grid" item xs={6}>
            <Typography variant="body1">
              {t("signup_labels.postcode")}:
            </Typography>
          </Grid>
          <Grid className="summary-grid" item xs={6}>
            <Typography variant="body1">{form_data.postcode}</Typography>
          </Grid>
          <Grid className="summary-grid" item xs={6}>
            <Typography variant="body1">{t("signup_labels.city")}:</Typography>
          </Grid>
          <Grid className="summary-grid" item xs={6}>
            <Typography variant="body1">{form_data.city}</Typography>
          </Grid>
          <Grid className="summary-grid" item xs={6}>
            <Typography variant="body1">
              {t("signup_labels.country")}:
            </Typography>
          </Grid>
          <Grid className="summary-grid" item xs={6}>
            <Typography variant="body1">{form_data.country}</Typography>
          </Grid>
          <Grid className="summary-grid" item xs={6}>
            <Typography variant="body1">
              {t("signup_labels.contact_tel")}:
            </Typography>
          </Grid>
          <Grid className="summary-grid" item xs={6}>
            <Typography variant="body1">{form_data.contact_tel}</Typography>
          </Grid>
          {/* <Grid className="summary-grid" item xs={6}>
            <Field name="captcha" component={Captcha} validate={[required]} />
          </Grid> */}
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Grid item xs={6}>
            <Button
              onClick={handleBack}
              variant="outlined"
              color="primary"
              className={classes.prevButtonContainer}
            >
              {t("buttons.back")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.finishButtonContainer}
            >
              {t("buttons.finish")}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

const selector = formValueSelector("UserSignupForm");

export default compose(
  withStyles(styles),
  withTranslation(),
  reduxForm({
    form: "UserSignupForm",
    destroyOnUnmount: false,
  }),
  connect((state) => {
    const first_name = selector(state, "first_name");
    const last_name = selector(state, "last_name");
    const email = selector(state, "email");
    const company = selector(state, "company");
    const address1 = selector(state, "address1");
    const address2 = selector(state, "address2");
    const postcode = selector(state, "postcode");
    const city = selector(state, "city");
    const country = selector(state, "country");
    const contact_name = selector(state, "contact_name");
    const contact_email = selector(state, "contact_email");
    const contact_tel = selector(state, "contact_tel");
    const form_data = {
      first_name,
      last_name,
      email,
      company,
      address1,
      address2,
      postcode,
      city,
      country,
      contact_name,
      contact_email,
      contact_tel,
    };

    return { form_data };
  })
)(ConfirmInfo);
