import React from 'react';
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Icon from "@material-ui/core/Icon/Icon";
import { Fab } from '@material-ui/core';

export const fabAddButton = (title, handler) => {
    return (
        <Tooltip title={title}>
            <Fab style={{float: 'right', marginTop: -20, marginRight: -20, borderRadius: "50%"}} variant="circular" color="primary"
                    aria-label="Add" size='medium'
                    onClick={handler}>
                <Icon>add</Icon>
            </Fab>
        </Tooltip>);

};