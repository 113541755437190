import React from "react";
import PropTypes from "prop-types";
import SelectField from "./selectFields/SelectField";
import { useTranslation } from "react-i18next";

const releaseStatuses = (status) => [
  { value: "ACTIVE", label: status.active },
  { value: "INACTIVE", label: status.inactive },
  { value: "CREATED", label: status.created },
];
const StatusSelectField = ({ name, ...other }) => {
  const { t } = useTranslation();
  return (
    <SelectField
      name={name}
      optionmap={releaseStatuses(t('statuses', { returnObjects: true }))}
      label={t('manageResults_labels.classification')}
      {...other}
    />
  );
};

StatusSelectField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default StatusSelectField
