import PropTypes from "prop-types";
import React from "react";
import { reduxForm } from "redux-form";
import Grid from "@material-ui/core/Grid/Grid";
import Button from "@material-ui/core/Button/Button";
import { withStyles } from "@material-ui/core/styles/index";
import { compose } from "recompose";
import Typography from "@material-ui/core/Typography/Typography";
import { DOCUMENTS_VERSION_SERVICE } from "../../common/services/availableServices";
import CheckBoxField from "../../common/fields/CheckBoxField";
import { required } from "../../common/fieldValidators";
import toastr from "toastr";
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    display: "flex",
    minWidth: "60vh",
  },
  container: {
    width: "100%",
  },
  inputField: {
    width: "100%",
  },
  submitRow: {
    marginTop: 20,
  },
});

const PublishNewDocumentView = ({
  handleSubmit,
  classes,
  onCancel,
  onSuccess,
  documentVersion,
}) => {
  const submitHandler = (values) => {
    const notify = values.notify === undefined ? false : values.notify;

    const versionForm = {
      notifyUsers: notify,
      id: documentVersion.id,
    };

    DOCUMENTS_VERSION_SERVICE.putValues(versionForm)
      .then((response) => {
        toastr.success("Version is published");
        onSuccess(response.data);
      })
      .catch((error) => {
        if (typeof error.response === "undefined") return;
        toastr.error(error.response.data.message);
      });
  };

  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <form
        noValidate
        autoComplete="off"
        className={classes.container}
        onSubmit={handleSubmit(submitHandler.bind(this))}
      >
        <Grid container spacing={1} direction="column">
          <Grid item md={12}>
            <Typography variant="h5" color="secondary">
              {t('documents_section.publish.title')}
            </Typography>
          </Grid>

          <Grid item>
            <CheckBoxField name="notify" label={t('documents_section.publish.notify')} />
          </Grid>

          <Grid
            container
            spacing={1}
            justifyContent="flex-end"
            className={classes.submitRow}
          >
            <Grid item>
              <Button onClick={onCancel}>{t('documents_section.publish.cancel')}</Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color={"primary"}
                type={"submit"}
                validate={[required]}
              >
                {t('documents_section.publish.save')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

PublishNewDocumentView.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  documentVersion: PropTypes.any.isRequired,
};

export default compose(
  withStyles(styles),
  reduxForm({
    form: "publishDocumentVersionForm",
  })
)(PublishNewDocumentView);
