import React from "react";
import PropTypes from "prop-types";
import { EMPLOYEE_AVAILABLE_SERVICE } from "../../services/availableServices";
import ServiceSelectField from "./ServiceSelectField";
import { useTranslation } from 'react-i18next';

const customMapperHandler = (values) => {
  return values.map((item) => {
    return {
      value: item.id,
      label:
        item.firstName + " " + item.lastName + " [" + item.clinicName + "]",
    };
  });
};

const AdminPhysiciansSelectField = ({  name, criteria, ...other }) => {
  const { t } = useTranslation();

  return (
    <ServiceSelectField
      name={name}
      service={EMPLOYEE_AVAILABLE_SERVICE}
      customMapper={customMapperHandler}
      label={t('select_field.physician')}
      criteria={criteria}
      {...other}
    />
  );
};

AdminPhysiciansSelectField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default AdminPhysiciansSelectField
