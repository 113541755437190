import { Field } from "redux-form";
import { MenuItem } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import SelectFieldComponent from "../components/SelectFieldComponent";

const SelectField = (props, { name, label, className }) => (
  <Field
    name={name}
    className={className}
    component={SelectFieldComponent}
    label={label}
    {...props}
  >
    {props.noneoptionenabled === true && <MenuItem key="none" value="" />}
    {props.optionmap &&
      ((!Array.isArray(props.optionmap) && isObjectMap(props)) ||
        (Array.isArray(props.optionmap) && idNameObjectArray(props)))}
  </Field>
);

function isObjectMap(props) {
  return Object.keys(props.optionmap).map((key) => {
    return (
      <MenuItem key={props.optionmap[key]} value={key}>
        {props.optionmap[key]}
      </MenuItem>
    );
  });
}

function idNameObjectArray(props) {
  return props.optionmap.map((key) => {
    return (
      <MenuItem key={key.value} value={key.value}>
        {key.label}
      </MenuItem>
    );
  });
}

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  noneoptionenabled: PropTypes.string,
  changeHandler: PropTypes.func,
  optionmap: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
      })
    ),
    PropTypes.object,
  ]),
};

SelectField.defaultProps = {
  noneoptionenabled: "true",
};

export default SelectField;
