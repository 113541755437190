import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import LoadingIndicator from "../../../common/components/LoadingIndicator";
import toastr from "toastr";
import { DETAILED_RESULT_SERVICE } from "../../../common/services/availableServices";
import PopupWindow from "../../../common/components/PopupWindow";
import EditNotesComponent from "./EditNotesComponent";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Icon from "@material-ui/core/Icon/Icon";
import ProfileGraph from "../Graphs/ProfileGraph";
import TextField from "@material-ui/core/TextField";
import { withTranslation } from 'react-i18next';

const styles = () => ({
  root: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
  },
  textField: {
    width: "100%",
  },
});

class ResultBasicView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      openModal: false,
    };

    this.editNotesButtonClickHandler =
    this.editNotesButtonClickHandler.bind(this);
    this.closeWindowHandler = this.closeWindowHandler.bind(this);
    this.successHandler = this.successHandler.bind(this);
  }

  componentDidMount() {
    this.fetchResult(this.props.resultId);
  }

  fetchResult(id) {
    DETAILED_RESULT_SERVICE.get(id)
      .then((response) => {
        this.setState({
          result: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        toastr.error(error.response.data.message);
      });
  }

  closeWindowHandler() {
    this.setState({
      openModal: false,
    });
  }

  successHandler() {
    this.setState(
      {
        loading: true,
        result: null,
        openModal: false,
      },
      () => this.fetchResult(this.props.resultId)
    );
  }

  editNotesButtonClickHandler() {
    this.setState({
      openModal: true,
    });
  }

  render() {
    const { classes, onCloseButtonClick, resultId, t } = this.props;
    const specLabels = t('detailed_result_view', { returnObjects: true });
    const { result, loading, openModal } = this.state;

    if (loading) return <LoadingIndicator />;

    return (
      <React.Fragment>
        <PopupWindow open={openModal} onClose={this.closeWindowHandler}>
          <EditNotesComponent
            resultId={resultId}
            notes={result.notes}
            onCancel={this.closeWindowHandler}
            onSuccess={this.successHandler}
          />
        </PopupWindow>
        <Grid container className={classes.root}>
          <Grid container item xs={4}>
            <Grid item xs={5}>
              <Typography>{specLabels.phoneBrand}:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="subtitle1">{result.phoneBrand}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography>{specLabels.phoneModel}:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="subtitle1">{result.phoneModel}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography>{specLabels.phoneName}:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="subtitle1">{result.phoneName}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography>{specLabels.appVersion}:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="subtitle1">{result.appVersion}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography>{specLabels.readerVersion}:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="subtitle1">
                {result.readerVersion}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography>{specLabels.batchNumber}:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="subtitle1">{result.batchNumber}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography>{specLabels.batchExpDate}:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="subtitle1">
                {result.batchExpirationDate}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography>{specLabels.cScore}:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="subtitle1">{result.cscore}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography>{specLabels.tScore}:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="subtitle1">{result.tscore}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography>{specLabels.tcRatio}:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="subtitle1">{result.tcRatio}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography>{specLabels.cPeakPosition}:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="subtitle1">
                {result.cpeakPosition}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography>{specLabels.tPeakPosition}:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="subtitle1">
                {result.tpeakPosition}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography>{specLabels.cThresh}:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="subtitle1">{result.cthresh}</Typography>
            </Grid>
          </Grid>

          <Grid container item xs={5} alignItems="center">
            <Grid item xs={3}>
              <Typography>
                {specLabels.notes}:
                <IconButton
                  color="primary"
                  onClick={this.editNotesButtonClickHandler}
                >
                  <Icon>edit_icon</Icon>
                </IconButton>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                value={result.notes}
                className={classes.textField}
                multiline
                rows={6}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <Typography>{specLabels.stripImage}:</Typography>
            </Grid>
            <Grid item xs={9}>
              <img
                src={"data:image/png;base64," + result.stripImage}
                alt={"error " + specLabels.stripImage}
              />
            </Grid>
          </Grid>
          <Grid container item xs={2} alignItems="center">
            <Grid item xs={5}>
              <Typography>{specLabels.image}:</Typography>
            </Grid>
            <Grid item xs={7}>
              <img
                src={"data:image/png;base64," + result.image}
                alt={"error " + specLabels.image}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <ProfileGraph
              baseline={result.baseline}
              profile={result.profile}
              cpeakPosition={result.cpeakPosition}
              tpeakPosition={result.tpeakPosition}
            />
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "flex-end",
            alignItems: "flex-end",
            padding: 16,
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={onCloseButtonClick}
          >
            {t('buttons.close')}
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles),
)(ResultBasicView);
