import React, { Component } from "react";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid/Grid";
import DocumentVersionContainer from "./Versions/DocumentVersionContainer";
import DocumentTranslationContainer from "./Translations/DocumentTranslationContainer";
import DocumentRepresentationContainer from "./DocumentRepresentation/DocumentRepresentationContainer";
import Button from "@material-ui/core/Button/Button";
import PopupWindow from "../common/components/PopupWindow";
import PublishNewDocumentView from "./Versions/PublishNewDocumentView";
import Typography from "@material-ui/core/Typography/Typography";
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
  gridItem: { width: "100%" },
  root: { width: "100%", minHeight: 600 },
  publishButton: { marginRight: 15, marginTop: 10 },
});

class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      version: null,
      translation: null,
      docView: null,
      publish: false,
      publishModal: false,
      checkPublish: false,
      newlyPublished: false,
    };

    this.handlePublishModal = this.handlePublishModal.bind(this);
    this.onSuccessPublish = this.onSuccessPublish.bind(this);
  }

  render() {
    const { classes, t } = this.props;
    const { version } = this.state;

    const isReadyToPublish =
      this.state.publish && this.state.version && !this.state.version.published;
    const publishLabel =
      isReadyToPublish && version
        ? `${t("documents_section.documents.publish")}: ${version.name}`
        : t("documents_section.documents.cannot_publish");

    return (
      <div>
        <PopupWindow
          open={this.state.publishModal}
          onClose={this.handlePublishModal}
        >
          <PublishNewDocumentView
            onCancel={this.handlePublishModal}
            documentVersion={version}
            onSuccess={this.onSuccessPublish}
          />
        </PopupWindow>
        <Grid container spacing={2} style={{ overflow: "auto" }}>
          <Grid item md={4} className={classes.root}>
            <Typography
              component="h4"
              variant="h4"
              color="secondary"
              gutterBottom
            >
              {t(this.props.match.params.documentName)}
            </Typography>
            <Grid container direction="column">
              <Grid item xd={4}>
                <DocumentVersionContainer
                  newlyPublished={this.state.newlyPublished}
                  selectedVersionHandler={this.selectedVersionHandler.bind(
                    this
                  )}
                  docName={this.props.match.params.documentName}
                />
              </Grid>
              <Grid item xd={4}>
                <DocumentTranslationContainer
                  checkPublish={this.state.checkPublish}
                  versionIsReadyToPublishHandler={this.versionIsReadyToPublishHandler.bind(
                    this
                  )}
                  selectedTranslationHandler={this.selectedTranslationHandler.bind(
                    this
                  )}
                  version={version}
                />
              </Grid>
              {version && !version.published && (
                <Grid item xd={4}>
                  <Button
                    className={classes.publishButton}
                    variant="contained"
                    color={"secondary"}
                    disabled={!isReadyToPublish}
                    onClick={this.handlePublishModal.bind(this)}
                  >
                    {publishLabel}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item md={8} className={classes.root}>
            <DocumentRepresentationContainer
              updateTranslationStatusHandler={this.updateTranslationStatusHandler.bind(
                this
              )}
              version={this.state.version}
              translation={this.state.translation}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  handlePublishModal() {
    this.setState({ publishModal: !this.state.publishModal });
  }

  onSuccessPublish(version) {
    this.setState({ publish: false });
    this.setState({ newlyPublished: true });
    this.handlePublishModal();
  }

  //If all translations for selected version are in status Finished that flag is true
  versionIsReadyToPublishHandler(publish) {
    this.setState({ publish });
    this.setState({ checkPublish: false });
  }

  //Update icon in translation in case of changing its status, also if the status is FINISHED, set flag to true
  updateTranslationStatusHandler(status) {
    const tempTrans = this.state.translation;
    tempTrans.status = status;
    this.setState({ translation: tempTrans });
    this.setState({ checkPublish: true });
  }

  selectedVersionHandler(version) {
    this.setState({ version });
    this.setState({ newlyPublished: false });
  }

  selectedTranslationHandler(selectedTranslation) {
    this.setState({ translation: selectedTranslation });
  }
}

Documents.propTypes = {
  documentName: PropTypes.string,
  version: PropTypes.shape({
    name: PropTypes.string,
    published: PropTypes.bool,
  }),
};

export default compose(withStyles(styles), withTranslation())(Documents);
