import React from "react";
import PropTypes from "prop-types";
import SelectField from "./SelectField";
import { useTranslation } from 'react-i18next';

const intervals = (intervals_labels) => [
  { value: "DAYS", label: intervals_labels.interval_day },
  { value: "WEEKS", label: intervals_labels.interval_week },
  { value: "MONTHS", label: intervals_labels.interval_month },
  { value: "YEARS", label: intervals_labels.interval_year },
];
const FrequencySelectField = ({
  name,
  ...other
}) => {
  const { t } = useTranslation();

  return (
    <SelectField
      name={name}
      optionmap={intervals(t("patient_details", { returnObjects: true }))}
      label={t('select_field.frequency_type')}
      {...other}
    />
  );
};

FrequencySelectField.propTypes = {
  name: PropTypes.string.isRequired,
};


export default FrequencySelectField
