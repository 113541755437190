import React, { Component } from "react";
import TextField from "../common/fields/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { email, phoneNumber, required } from "../common/fieldValidators";
import { reduxForm } from "redux-form";
import Button from "@material-ui/core/Button";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";

const styles = () => ({
  expandForm: {
    width: "100%",
  },
  rightInput: {
    width: "100%",
  },
  prevButton: {
    float: "left",
  },
  nextButton: {
    float: "right",
  },
  formContainer: {
    minHeight: "650px",
    maxHeight: "650px",
  },
});

class UserForm extends Component {
  render() {
    const { classes, handleSubmit, t } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1} className={classes.formContainer}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {t("signup_labels.provide_contact_info")}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t("signup_labels.provide_contact_info_subtitle")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="first_name"
              label={t("signup_labels.first_name")}
              validate={[required]}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="last_name"
              label={t("signup_labels.last_name")}
              className={classes.rightInput}
              validate={[required]}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.expandForm}
              name="email"
              label={t("signup_labels.email")}
              validate={[email, required]}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.expandForm}
              name="contact_tel"
              label={t("signup_labels.contact_tel")}
              validate={[phoneNumber, required]}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Grid item xs={12} sm={6}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.prevButton}
              onClick={this.props.onHome}
            >
              {t("buttons.home")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.nextButton}
            >
              {t("buttons.next")}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
  reduxForm({
    form: "UserSignupForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })
)(UserForm);
