import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { reduxForm } from "redux-form";
import { PagedTableContainer } from "../common/components/PagedTable/PagedTableContainer";
import {
  AVAILABLE_BATCH_SERVICE,
  EXPORT_BATCHES_SERVICE,
} from "../common/services/availableServices";
import { compose } from "recompose";
import { columnType } from "../common/components/PagedTable/columnType";
import SearchTextField from "../common/fields/SearchTextField";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import PermissionContainer from "../Auth/PermissionContainer";
import { permission } from "../Auth/permissions";
import { managePageStyles } from "../styles";
import ReleaseStatusSelectField from "../common/fields/selectFields/ReleaseStatusSelectField";
import ReleaseStatusComponent from "./ReleaseStatusComponent";
import Dialog from "@material-ui/core/Dialog/Dialog";
import BatchDetailsTabs from "./BatchDetailsTabs";
import ExportExcelButton from "../common/components/ExportExcelButton";
import { withTranslation } from 'react-i18next';

const styles = () => ({
  dialogPaperClass: {
    padding: 0,
    minHeight: "60vh",
    height: "85vh",
    width: "120vh",
  },
});

class ManageBatches extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      criteria: null,
      openModal: false,
      batchNumber: null,
      loadingTableData: true,
    };
    this.tableColumnSetup();
  }

  createButtonClickHandler = () => {
    this.setState({
      openModal: true,
      batchNumber: null,
    });
  };

  tableColumnSetup() {
    this.columns = [
      { key: "number", type: columnType.text },
      { key: "expirationDate", type: columnType.text },
      { key: "creationDate", type: columnType.text },
      {
        key: "status",
        type: columnType.text,
        customCellRenderer: this.statusCellRenderer,
      },
      {
        key: "details",
        type: columnType.text,
        customCellRenderer: this.detailsCellRenderer,
        sortable: false,
      },
    ];
  }

  statusCellRenderer = (rowId, columnValue) => {
    return <ReleaseStatusComponent releaseStatus={columnValue} />;
  };

  detailsCellRenderer = (rowId) => {
    return (
      <Button onClick={this.detailsButtonClickHandler(rowId)}>
        {this.props.t('buttons.details')}
      </Button>
    );
  };

  detailsButtonClickHandler = (rowId) => () => {
    this.setState({
      openModal: true,
      batchNumber: rowId,
    });
  };

  closeWindowHandler = () => {
    this.setState({ openModal: false });
  };

  searchButtonClickHandler = (values) => {
    let queryParams = {
      search: values.search,
    };

    if ("status" in values) {
      queryParams = {
        ...queryParams,
        status: values.status,
      };
    }

    this.setState({
      criteria: queryParams,
      loadingTableData: true,
    });
  };
  successHandler = () => {
    this.setState({
      batchNumber: null,
      openModal: false,
      loadingTableData: true,
    });
  };

  loadingDataFinishHandler = () => {
    this.setState({
      loadingTableData: false,
    });
  };

  render() {
    const { handleSubmit,  classes, t } = this.props;
    const specLabels = t('manageBatches_labels', { returnObjects: true });
    const { criteria, openModal, loadingTableData } = this.state;

    return (
      <div>
        <Dialog
          classes={{ paper: classes.dialogPaperClass }}
          maxWidth={false}
          open={openModal}
          onClose={this.closeWindowHandler}
        >
          <BatchDetailsTabs
            batchNumber={this.state.batchNumber}
            onCancel={this.closeWindowHandler}
            onSuccess={this.successHandler}
          />
        </Dialog>

        <Grid container className={classes.header}>
          <Grid item xs={4}>
            <Typography variant="h5" color="secondary">
              {specLabels.page_title}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid item>
                <ExportExcelButton
                  service={EXPORT_BATCHES_SERVICE}
                  criteria={this.state.criteria}
                />
              </Grid>
              <Grid item>
                <PermissionContainer
                  allowedPermissions={[permission.batches_add]}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    onClick={this.createButtonClickHandler}
                  >
                    {specLabels.create_batch_button}
                  </Button>
                </PermissionContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <form onSubmit={handleSubmit(this.searchButtonClickHandler)}>
          <Grid spacing={1} container className={classes.criteria}>
            <Grid item md={4} xs={12}>
              <SearchTextField
                name="search"
                className={classes.criteriaField}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <div style={{ marginTop: 16, marginBottom: 8 }}>
                <ReleaseStatusSelectField
                  name="status"
                  className={classes.criteriaField}
                />
              </div>
            </Grid>
            <Grid
              item
              container
              md={4}
              xs={12}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button variant="contained" color="primary" type="submit">
                {t('buttons.search')}
              </Button>
            </Grid>
          </Grid>
        </form>
        <PagedTableContainer
          rowKey="number"
          orderBy="number"
          order="asc"
          columns={this.columns}
          criteria={criteria}
          service={AVAILABLE_BATCH_SERVICE}
          loadingData={loadingTableData}
          onLoadingDataFinish={this.loadingDataFinishHandler}
          errorMessage={t('table.error_message')}
          emptyResultMessage={t('table.empty_result')}
          columnLabels={specLabels.columns}
        />
      </div>
    );
  }
}

export default compose(
  withStyles(
    (theme) => ({
      ...managePageStyles(theme),
      ...styles(theme),
    }),
    { withTheme: true }
  ),
  withTranslation(),
  reduxForm({ form: "BatchSearch" }),
)(ManageBatches);
