import React, { Component } from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { initialize, reduxForm } from "redux-form";
import Grid from "../../node_modules/@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "../common/fields/TextField";
import CountrySelectField from "../common/fields/selectFields/CountrySelectField";
import toastr from "toastr";
import { CLINIC_SERVICE } from "../common/services/availableServices";
import { email, phoneNumber, required } from "../common/fieldValidators";
import FormGroup from "@material-ui/core/FormGroup";
// import OrganizationSelectField from "../common/fields/selectFields/OrganizationSelectField";
import * as PropTypes from "prop-types";
import StatusSelectField from "../common/fields/StatusSelectField";
import {
  constructErrorMessage,
  FormMode,
  initializeFormMode,
} from "../common/FormUtils";
import PermissionContainer from "../Auth/PermissionContainer";
import { permission as permissions } from "../Auth/permissions";
import LoadingIndicator from "../common/components/LoadingIndicator";
import FormButtons from "../common/components/FormButtons";
import { connect } from "react-redux";
import CheckBoxField from "../common/fields/CheckBoxField";
import { withTranslation } from "react-i18next";

const styles = () => ({
  root: {
    flexGrow: 1,
    display: "flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexGrow: 1,
  },
  inputField: {
    marginRight: 100,
  },
  buttonMargin: {
    marginRight: 32,
  },
  formRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    alignContent: "flex-start",
  },
  city: {
    marginLeft: 32,
    marginRight: 100,
    flexGrow: 3,
  },
});

const reduxFormName = "clinicForm";

class ClinicDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clinicId: this.props.clinicId,
      clinic: null,
      formMode: initializeFormMode(this.props.clinicId),
      loading: false,
      isModified: false,
    };
    this.editButtonClickHandler = this.editButtonClickHandler.bind(this);
    this.cancelButtonClickHandler = this.cancelButtonClickHandler.bind(this);
    this.closeButtonClickHandler = this.closeButtonClickHandler.bind(this);
    this.editable = this.editable.bind(this);
  }

  componentDidMount() {
    if (FormMode.VIEW === this.state.formMode) {
      this.fetchClinic(this.state.clinicId);
    }
  }

  closeButtonClickHandler() {
    if (this.state.isModified) {
      this.props.onSuccess();
    } else {
      this.props.onCancel();
    }
  }

  cancelButtonClickHandler() {
    this.setState(
      {
        formMode: FormMode.VIEW,
      },
      () => this.fetchClinic(this.state.clinicId)
    );
  }

  editButtonClickHandler() {
    this.setState({
      formMode: FormMode.EDIT,
    });
  }

  submitFormHandler(values) {
    values.organizationId = 1;
    if (values.useMfaEmployee === undefined) values.useMfaEmployee = false;

    if (values.useMfaPatient === undefined) values.useMfaPatient = false;

    if (FormMode.CREATE === this.state.formMode) {
      this.createClinic(values);
    } else if (FormMode.EDIT === this.state.formMode) {
      this.updateClinic(values, this.state.clinicId);
    }
  }

  createClinic(values) {
    this.setState({
      loading: true,
    });

    CLINIC_SERVICE.post(values)
      .then((response) => {
        this.setState({
          loading: false,
          clinicId: response.data.id,
          clinic: response.data,
          isModified: true,
          formMode: FormMode.VIEW,
        });

        this.props.dispatch(initialize(reduxFormName, response.data));

        toastr.success(this.props.t("clinic_details.clinicCreated"));
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });

        if (!error.handledGlobally)
          toastr.error(constructErrorMessage("Can't create clinic.", error));
      });
  }

  updateClinic(values, clinicId) {
    this.setState({
      loading: true,
    });

    CLINIC_SERVICE.put(values, clinicId)
      .then((response) => {
        this.setState({
          loading: false,
          isModified: true,
          clinic: response.data,
          formMode: FormMode.VIEW,
        });

        this.props.dispatch(initialize(reduxFormName, response.data));

        toastr.success(this.props.t("clinic_details.clinicUpdated"));
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });

        if (!error.handledGlobally)
          toastr.error(constructErrorMessage("Can't update clinic.", error));
      });
  }

  fetchClinic(clinicId) {
    this.setState({
      loading: true,
    });

    CLINIC_SERVICE.get(clinicId)
      .then((response) => {
        this.props.dispatch(initialize(reduxFormName, response.data));
        this.setState({
          clinic: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        if (!error.handledGlobally)
          toastr.error(constructErrorMessage("Can't fetch clinic.", error));
      });
  }

  editable() {
    return PermissionContainer.checkAllowedPermissions(
      [permissions.clinic_edit],
      this.props.user.permissions
    );
  }

  render() {
    const { handleSubmit, classes, t } = this.props;

    const { formMode, loading } = this.state;

    const disabled = formMode === FormMode.VIEW;

    return (
      <div className={classes.root}>
        {loading && <LoadingIndicator />}
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(this.submitFormHandler.bind(this))}
          className={classes.container}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5" color="secondary" gutterBottom>
                {t("clinic_details.page_title")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormGroup className={classes.container}>
                <TextField
                  name={"name"}
                  label={t("clinic_details.name")}
                  className={classes.inputField}
                  validate={[required]}
                  disabled={disabled}
                />
                <CountrySelectField
                  name={"countryCode"}
                  validate={[required]}
                  className={classes.inputField}
                  disabled={disabled}
                />
                <TextField
                  name={"addressLine1"}
                  label={t("clinic_details.address1")}
                  className={classes.inputField}
                  validate={[required]}
                  disabled={disabled}
                />
                <TextField
                  name={"addressLine2"}
                  label={t("clinic_details.address2")}
                  className={classes.inputField}
                  disabled={disabled}
                />
                <div className={classes.formRow}>
                  <TextField
                    name={"postalCode"}
                    label={t("clinic_details.postcode")}
                    validate={[required]}
                    disabled={disabled}
                  />
                  <TextField
                    name={"city"}
                    label={t("clinic_details.city")}
                    className={classes.city}
                    validate={[required]}
                    disabled={disabled}
                  />
                </div>
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormGroup>
                <TextField
                  name={"contactName"}
                  label={t("clinic_details.contactName")}
                  className={classes.inputField}
                  validate={[required]}
                  disabled={disabled}
                />
                <TextField
                  name={"contactEmail"}
                  label={t("clinic_details.contactEmail")}
                  className={classes.inputField}
                  validate={[required, email]}
                  disabled={disabled}
                />
                <TextField
                  name={"contactTel"}
                  label={t("clinic_details.contactTel")}
                  className={classes.inputField}
                  validate={[required, phoneNumber]}
                  disabled={disabled}
                />
                {/* <OrganizationSelectField
                  name={"organizationId"}
                  className={classes.inputField}
                  validate={[required]}
                  disabled={disabled}
                /> */}
                <StatusSelectField
                  name={"status"}
                  label={t("clinic_details.status")}
                  className={classes.inputField}
                  validate={[required]}
                  disabled={disabled}
                />
              </FormGroup>
              <FormGroup row={true}>
                <CheckBoxField
                  name={"useMfaEmployee"}
                  label={t("clinic_details.mfa_staff")}
                  className={classes.inputField}
                  disabled={disabled}
                />

                {/* <CheckBoxField
                  name={"useMfaPatient"}
                  label={t("clinic_details.mfa_patient")}
                  className={classes.inputField}
                  disabled={disabled}
                /> */}
              </FormGroup>
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "flex-end",
              alignItems: "flex-end",
              padding: 16,
            }}
          >
            <FormButtons
              formMode={formMode}
              onCloseButtonClick={this.closeButtonClickHandler}
              onCancelButtonClick={this.cancelButtonClickHandler}
              onEditButtonClick={this.editButtonClickHandler}
              actionButtonDisabled={loading}
              editModeEnabled={this.editable()}
            />
          </div>
        </form>
      </div>
    );
  }
}

ClinicDetails.propTypes = {
  clinicId: PropTypes.number,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

function mapStateToProps({ auth }) {
  return { user: auth.user };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation(),
  reduxForm({
    form: reduxFormName,
  })
)(ClinicDetails);
