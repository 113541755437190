import React from "react";
import { Route } from "react-router-dom";
import PermissionContainer from "../Auth/PermissionContainer";
import PageNotFound from "../common/components/PageNotFound";

const ProtectedRoute = ({ path, component, allowedPermissions }) => {
  return (
    <PermissionContainer
      allowedPermissions={allowedPermissions}
      alternativeComponent={<PageNotFound />}
    >
      <Route path={path} component={component} />
    </PermissionContainer>
  );
};

export default ProtectedRoute;
