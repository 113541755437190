import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useTranslation } from "react-i18next";

const CheckBoxFieldComponent = ({
  input,
  label,
  className,
  required,
  name,
  style,
  meta: { touched, error },
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <FormControl className={className} margin={"normal"} style={style}>
      <FormControlLabel
        control={
          <Checkbox
            label={label}
            checked={Boolean(input.value)}
            onClick={input.onChange}
            {...props}
          />
        }
        label={label}
      />
      <FormHelperText error={touched && error !== undefined}>
        {touched && error} {!(touched && error) && required && t('required')}
      </FormHelperText>
    </FormControl>
  );
};

export default CheckBoxFieldComponent

