import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose } from "recompose";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { FormGroup, Typography } from "@material-ui/core";
import { formValueSelector, reduxForm } from "redux-form";
import { email, required } from "../common/fieldValidators";
import PropTypes from "prop-types";
import PulseLoader from "react-spinners/PulseLoader";

import { useTranslation } from "react-i18next";

import TextField from "../common/fields/TextField";
import { connect } from "react-redux";
import { secondary } from "../styles";
const styles = () => ({
  showHideAnimated: {},
});

const FAQContactForm = ({ handleSubmit }) => {
  const [visibility, setVisibility] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [t] = useTranslation();

  const submitHandler = (values) => {
    setLoading(true);

    fetch(`${process.env.REACT_APP_API_ROOT}contact-form`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf8",
      },
      body: JSON.stringify(values),
    }).then((res) => {
      if (res.ok) {
        setVisibility(false);
        setLoading(false);
      } else {
        setLoading(false);
        setError(true);
      }
    });
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <Typography
          variant="h6"
          style={{
            opacity: !visibility ? "1" : "0",
            transition: "all .3s",
            visibility: !visibility ? "visible" : "hidden",
          }}
        >
          {t("contact_form_submitted")}
        </Typography>
      </div>

      <form
        noValidate
        onSubmit={handleSubmit(submitHandler.bind(this))}
        style={{
          opacity: !visibility ? "0" : "1",
          transition: "all .3s",
          visibility: !visibility ? "hidden" : "visible",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" align="center">
              {t("faq.contactFormTitle")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <TextField
                name={"email"}
                required
                type="text"
                label={t("signup_labels.contact_email")}
                validate={[required, email]}
                fullWidth
              />
              <TextField
                name={"message"}
                label={t("your_question")}
                rows={5}
                multiline={true}
                required
                validate={[required]}
                fullWidth
                inputProps={{ maxLength: 1000 }}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit">
              {t("buttons.send")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <PulseLoader size={20} loading={loading} color={secondary} />
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Typography variant="h6" align="center" style={{ color: "#FF9494", padding: "20px", border: "1px solid #FF9494"}}>
                {t("faq.errorWhenSubmit")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </form>
    </>
  );
};

FAQContactForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const selector = formValueSelector("FAQContactForm");

export default compose(
  withStyles(styles),
  reduxForm({
    form: "contactForm",
  }),
  connect((state) => {
    const email = selector(state, "email");
    const message = selector(state, "message");
    const formData = {
      email,
      message,
    };
    return { formData };
  })
)(FAQContactForm);
