import { authenticationService } from "../index";
import * as axios from "axios";
import toastr from "toastr";
import React from "react";
import {
  loginFailed,
  loginRequest,
  logoutRequest,
  tokenAcquired,
} from "../Auth/actions";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";

class ServerErrorHandler extends React.Component {
  constructor(props) {
    super(props);

    this.errorResponseHandler = this.errorResponseHandler.bind(this);
    this.handleUnauthorizedError = this.handleUnauthorizedError.bind(this);
    this.logout = this.logout.bind(this);
    this.setAccessToken = this.setAccessToken.bind(this);
    this.onAccessTokenFetched = this.onAccessTokenFetched.bind(this);
    this.addSubscriber = this.addSubscriber.bind(this);

    axios.interceptors.request.use(this.setAccessToken);

    axios.interceptors.response.use(
      (response) => response,
      this.errorResponseHandler
    );
  }

  setAccessToken(config) {
    if (this.props.accessToken)
      config.headers["Authorization"] = "Bearer " + this.props.accessToken;

    return config;
  }

  errorResponseHandler(error) {
    // check for errorHandle config
    if (
      error.config.hasOwnProperty("errorHandle") &&
      error.config.errorHandle === false
    ) {
      error.handledGlobally = false;
      return Promise.reject(error);
    }

    if (error.response === undefined) {
      console.error("Network problem: ", error);
      toastr.error("Network problem");
      error.handledGlobally = true;
      return Promise.reject(error);
    }

    if (error.response) {
      let status = error.response.status;
      if (status === 401) {
        return this.handleUnauthorizedError(error);
      } else if (status === 403) {
        return this.handleForbiddenError(error);
      } else if (status === 405) {
        return this.handleMethodNotAllowedError(error);
      } else if (status === 423) {
        return this.handleLockedError(error);
      } else if (status === 451) {
        return this.handleUnavailableForLegalReason(error);
      }
      //handle client errors directly
      else if (status >= 400 && status < 500) {
        error.handledGlobally = false;
        return Promise.reject(error);
      } else {
        return this.handleGeneralError(error);
      }
    }
  }

  isAlreadyFetchingAccessToken = false;
  subscribers = [];

  onAccessTokenFetched(access_token) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(access_token)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  handleUnauthorizedError(error) {
    if (!this.props.isAuthenticated) {
      console.error("Not authorized: ", error);
      toastr.error("You are not authorized");
      error.handledGlobally = true;
      return Promise.reject(error);
    }

    const originalRequest = error.config;

    if (!this.isAlreadyFetchingAccessToken) {
      this.isAlreadyFetchingAccessToken = true;

      authenticationService.acquireNewToken((authResult) => {
        this.props.tokenAcquired(authResult);
        let decodedToken = jwt_decode(authResult.accessToken);

        let delay = decodedToken.iat * 1000 - Date.now();
        if (delay > 0) {
          console.info(
            "Token is not valid yet, need wait ",
            delay,
            " milliseconds before call API. Probably can be fixed by synchronization of time between Auth0 and API server"
          );
          setTimeout(() => {
            this.isAlreadyFetchingAccessToken = false;
            return this.onAccessTokenFetched(authResult.accessToken);
          }, delay);
        } else {
          this.isAlreadyFetchingAccessToken = false;
          return this.onAccessTokenFetched(authResult.accessToken);
        }
      }, this.logout);
    }
    const retryOriginalRequest = new Promise((resolve) => {
      this.addSubscriber((accessToken) => {
        originalRequest.headers.Authorization = "Bearer " + accessToken;
        originalRequest.errorHandle = false;
        resolve(axios(originalRequest));
      });
    });
    return retryOriginalRequest;
  }

  logout() {
    this.props.logoutRequest();
    try {
      authenticationService.logout();
    } catch (e) {
      this.props.logoutFailed(e.toString());
    }
  }

  handleForbiddenError(error) {
    console.error("Forbidden access error: ", error);
    toastr.error("You don't have permission for this feature");
    error.handledGlobally = true;
    return Promise.reject(error);
  }

  handleLockedError(error) {
    console.error("Locked error: ", error);
    toastr.error(
      "Clinic Administrator has not signed the latest Data Processing Agreement"
    );
    error.handledGlobally = true;
    return Promise.reject(error);
  }

  handleMethodNotAllowedError(error) {
    console.error("Method not allowed - check url: ", error);
    toastr.error("Server Error, contact administrator");
    error.handledGlobally = true;
    return Promise.reject(error);
  }

  handleUnavailableForLegalReason(error) {
    console.error(
      "Unavailable for legal reason, required documents are not accepted: ",
      error
    );
    toastr.info("New agreements available, please read and accept");

    this.props.history.push("/accept_form");

    error.handledGlobally = true;
    return Promise.reject(error);
  }

  handleGeneralError(error) {
    console.error("General error: ", error);
    toastr.error("Unexpected exception occurred");
    error.handledGlobally = true;
    return Promise.reject(error);
  }

  render() {
    return "";
  }
}

function mapStateToProps({ auth }) {
  const { accessToken, expiresAt, isFetching, isAuthenticated } = auth;

  return { accessToken, expiresAt, isFetching, isAuthenticated };
}

export default compose(
  withRouter,
  connect(mapStateToProps, {
    logoutRequest,
    loginRequest,
    loginFailed,
    tokenAcquired,
  })
)(ServerErrorHandler);
