import React, { Component } from "react";
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  API_VERSION_SERVICE,
  RESET_PASSWORD_SERVICE,
} from "../common/services/availableServices";
import toastr from "toastr";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import FormGroup from "@material-ui/core/FormGroup";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import { authenticationService } from "../index";

const styles = (theme) => ({
  button: {
    marginBottom: 20,
  },
  form: {
    width: "100%",
  },
  formControl: {
    marginBottom: 40,
  },
  input: {
    color: theme.palette.text.primary,
  },
});

class MyPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apiVersion: "",
    };
  }

  componentDidMount() {
    this.apiVersion();
  }

  resetPassword(email) {
    RESET_PASSWORD_SERVICE.post(email["email"])
      .then(() => {
        toastr.success("Reset password email sent!");
      })
      .catch((error) => {
        if (!error.response || error.response.status >= 500) {
          toastr.error(this.props.t("reset_password.error_message"));
        }
      });
  }

  apiVersion() {
    API_VERSION_SERVICE.getAll()
      .then((response) => {
        this.setState({ apiVersion: response.data });
      })
      .catch((error) => {
        if (!error.response || error.response.status >= 500) {
          toastr.error(this.props.t("reset_password.error_message"));
        }
      });
  }

  render() {
    const { auth, classes, t } = this.props;
    const { name, surname, email, clinic } = auth.user;
    const myPageLabels = t('myPage_labels', { returnObjects: true });
    const buttonLabels = t('buttons', { returnObjects: true});
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5" color="secondary" gutterBottom>
            {myPageLabels.page_title}
          </Typography>
        </Grid>
        <Grid item container xs={6}>
          <FormGroup className={classes.form}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="FirstName" className={classes.input}>
                {myPageLabels.firstName}
              </InputLabel>
              <Input
                id="FirstName"
                value={name}
                disableUnderline={true}
                classes={{ disabled: classes.input }}
                disabled
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="LastName" className={classes.input}>
                {myPageLabels.lastName}
              </InputLabel>
              <Input
                id="LastName"
                value={surname}
                disableUnderline={true}
                classes={{ disabled: classes.input }}
                disabled
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="Email" className={classes.input}>
                {myPageLabels.email}
              </InputLabel>
              <Input
                id="Email"
                value={email}
                disableUnderline={true}
                classes={{ disabled: classes.input }}
                disabled
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="ClinicName" className={classes.input}>
                {myPageLabels.clinicName}
              </InputLabel>
              <Input
                id="ClinicName"
                value={clinic}
                disableUnderline={true}
                classes={{ disabled: classes.input }}
                disabled
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="Roles" className={classes.input}>
                {myPageLabels.roles}
              </InputLabel>
              <Input
                id="Roles"
                value={auth.user.roles.join(", ")}
                disableUnderline={true}
                classes={{ disabled: classes.input }}
                disabled
                multiline
              />
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item container xs={6}>
          <FormGroup>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="ApiVersion" className={classes.input}>
                {myPageLabels.apiVersion}
              </InputLabel>
              <Input
                id="ApiVersion"
                value={this.state.apiVersion}
                disableUnderline={true}
                classes={{ disabled: classes.input }}
                disabled
                multiline
              />
            </FormControl>

            <Button
              onClick={() => this.resetPassword({ email })}
              className={classes.button}
              variant={"outlined"}
              color={"primary"}
            >
              {" "}
              {buttonLabels.reset_password}
            </Button>

            <Button
              variant={"outlined"}
              color={"primary"}
              component={Link}
              to={"/my_page/agreements"}
            >
              {myPageLabels.view_agreements}
            </Button>

          </FormGroup>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation(),
)(MyPage);
