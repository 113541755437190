import React from "react";
import Chip from "@material-ui/core/Chip/Chip";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  label: {
    color: "black",
  },
});

const classificationColors = {
  LOW: "#00a65a",
  MODERATE: "#ff851b",
  HIGH: "#f56954",
};

function mapClassification(classification) {
  switch (classification) {
    case 'SEVERE': {
      return 'HIGHT';
    }
    case 'MILD': {
      return 'LOW';
    }
    default: {
      return classification;
    }
  }
}

const ClassificationComponent = ({ classification, classes }) => {
  classification = mapClassification(classification)
  
  const inlineStyle = {
    background: classificationColors[classification],
  };

  return (
    <Chip
      label={classification}
      style={inlineStyle}
      classes={{ label: classes.label }}
    />
  );
};
export default withStyles(styles)(ClassificationComponent);
