import React from "react";
import PropTypes from "prop-types";
import ServiceSelectField from "./ServiceSelectField";
import { useTranslation } from 'react-i18next';

const customMapper = (roles) => {
  return Object.keys(roles).map((role) => {
    return {
      value: role,
      label: roles[role],
    };
  });
};

const RoleSelectField = (props) => {
  let { name, ...other } = props;

  const { t } = useTranslation();

  return (
    <ServiceSelectField
      name={name}
      label={t('staff_users.roles')}
      optionmap={customMapper(t('roles', { returnObjects: true }))}
      {...other}
    />
  );
};

RoleSelectField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default RoleSelectField;
