import React from "react";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { loginRequest, logoutFailed, logoutRequest } from "../Auth/actions";
import { authenticationService } from "../index";
import PermissionContainer from "../Auth/PermissionContainer";
import { compose } from "recompose";
import { UserLabelComponent } from "./UserLabelComponent";
import { useTranslation } from "react-i18next";

const LogComponent = ({ user, ...other }) => {
  const { loginRequest, loginFailed, logoutRequest, logoutFailed } = other;
  const { t } = useTranslation();

  const logInHandler = () => {
    loginRequest();
    try {
      authenticationService.login();
    } catch (e) {
      loginFailed(e);
    }
  };

  const logOutHandler = () => {
    logoutRequest();
    try {
      authenticationService.logout();
    } catch (e) {
      logoutFailed(e.toString());
    }
  };

  return (
    <React.Fragment>
      <PermissionContainer onlyForUnauthenticated={true}>
        <Button onClick={logInHandler}>{t('log.login')}</Button>
      </PermissionContainer>
      <PermissionContainer onlyForAuthenticated={true}>
        <div>
          <UserLabelComponent user={user} />
          <Button onClick={logOutHandler}>{t('log.log_out')}</Button>
        </div>
      </PermissionContainer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default compose(
  connect(mapStateToProps, {
    loginRequest,
    logoutRequest,
    logoutFailed,
  }),
)(LogComponent);
