import React from "react";
import PropTypes from "prop-types";
import { COUNTRY_SERVICE } from "../../services/availableServices";
import ServiceSelectField from "../selectFields/ServiceSelectField";
import { useTranslation } from 'react-i18next';


const customMapperHandler = (values) => {
  return values.map((item) => {
    return {
      value: item.code,
      label: item.name,
    };
  });
};

const CountrySelectField = ({ label, name, ...other }) => {
  const { t } = useTranslation()

  return (
  <ServiceSelectField
      name={name}
      service={COUNTRY_SERVICE}
      customMapper={customMapperHandler}
      label={t("signup_labels.country")}
      {...other}
    />
  );
};

CountrySelectField.propTypes = {
  name: PropTypes.string.isRequired,
};

const mapTranslationsToProps = ({ labels }) => {
  return { label: labels.select_field.country };
};

export default CountrySelectField
