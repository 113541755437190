import React from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { withTranslation } from 'react-i18next';

import PropTypes from "prop-types";
import AutocompleteSelectField from "../selectFields/AutocompleteSelectField";

class AutoCompleteComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
    };
  }

  render() {
    const {
      input,
      label,
      name,
      meta: { touched, error },
      children,
      className,
      required,
      optionmap,
      value,
      onChange,
      t,
      ...props
    } = this.props;

    const menuOpen = this.state.menuOpen;

    return (
      <FormControl
        error
        className={className}
        required={required}
        margin={"normal"}
        style={menuOpen ? { zIndex: 1 } : { zIndex: 0 }}
      >
        <AutocompleteSelectField
          optionmap={optionmap}
          placeholder={label}
          value={optionmap.find((item) => item.value === input.value)}
          onChange={(event) => input.onChange(event.value)}
          children={children}
          error={error && touched}
          name={name}
          {...props}
          onMenuOpen={() => {
            this.setState({ menuOpen: true });
          }}
          onMenuClose={() => {
            this.setState({ menuOpen: false });
          }}
        />
        <FormHelperText error={touched && error !== undefined}>
          {touched && error} {!(touched && error) && required && t('required')}
        </FormHelperText>
      </FormControl>
    );
  }
}

AutoCompleteComponent.defaultProps = {
  optionmap: [],
};

AutoCompleteComponent.propTypes = {
  optionmap: PropTypes.array,
};

export default withTranslation()(AutoCompleteComponent)
