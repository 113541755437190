import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import {columnType} from "./columnType";


const TableHeaderCell = ({type, children}) =>

    <TableCell numeric={(type === columnType.numeric).toString()} padding={'checkbox'}>
        {children}
    </TableCell>;

export default TableHeaderCell


