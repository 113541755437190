import React from "react";
import PropTypes from "prop-types";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import { FormControl } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const TranslationSelectField = (props) => {
  let { translations, selectedTranslation, handleChange } = props;

  var selected = selectedTranslation === null ? "" : selectedTranslation;

  const { t } = useTranslation();

  return (
    <FormControl>
      <InputLabel id="lang-input-label" style={{ position: "relative", padding: "5px 30px 0px 0", transform: "unset"}}>
        {t('select_field.language')}
      </InputLabel>
      <Select value={selected} onChange={handleChange} labelId="lang-input-label">
        <MenuItem style={{ padding: "3px 7px" }} value={null}>
          NONE
        </MenuItem>
        {renderItems(translations)}
      </Select>
    </FormControl>
  );
};

function renderItems(items) {
  return items.map((key) => {
    return (
      <MenuItem
        key={key.documentRepresentationId}
        value={key}
        style={{ padding: "3px 7px" }}
      >
        {key.languageName}
      </MenuItem>
    );
  });
}

TranslationSelectField.propTypes = {
  translations: PropTypes.array.isRequired,
  selectedTranslation: PropTypes.object,
  handleChange: PropTypes.func,
};

export default TranslationSelectField
