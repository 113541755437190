import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "../../node_modules/@material-ui/core/Button/Button";
import withStyles from "../../node_modules/@material-ui/core/styles/withStyles";
import { compose } from "recompose";
import { withTranslation } from 'react-i18next';

const styles = () => ({
  doneButton: {
    marginTop: "20px",
  },
});

class Done extends React.Component {
  render() {

    const { classes, t } = this.props;
    const labels = t('signup_labels', {returnObjects: true});

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography>{labels.done}</Typography>
        </Grid>
        <Grid item xs={12} className={"signup-buttons"}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.props.onHome}
            className={classes.doneButton}
          >
            {t('buttons.home')}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
)(Done);
