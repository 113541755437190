import React from "react";
import PropTypes from "prop-types";
import SelectField from "./selectFields/SelectField";
import { useTranslation } from "react-i18next";

const ActiveSelectField = ({ name, className }) => {
  const { t } = useTranslation();
  const activeTranslation = t("active", { returnObjects: true });

  return (
    <SelectField
      name={name}
      className={className}
      label={t("fields.status")}
      optionmap={activeTranslation}
    />
  );
};

ActiveSelectField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ActiveSelectField;
