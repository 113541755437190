import React from "react";
import LoadingIndicator from "../common/components/LoadingIndicator";
import { connect } from "react-redux";
import { logoutFailed, logoutSuccess } from "./actions";
import { Redirect } from "react-router-dom";

class LogoutCallbackHandlerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { running: true };
    this.logoutSuccessHandler = this.logoutSuccessHandler.bind(this);
  }

  logoutSuccessHandler() {
    try {
      this.props.logoutSuccess();
      // history.push('/');
    } catch (error) {
      console.error(error);
      this.props.logoutFailed(error);
      // history.push('/');
    }
    this.setState({ running: false });
  }

  componentDidMount() {
    this.logoutSuccessHandler();
  }

  render() {
    return (
      <div>
        <LoadingIndicator />
        {!this.state.running && <Redirect to="/" />}
      </div>
    );
  }
}

export default connect(null, {
  logoutSuccess,
  logoutFailed,
})(LogoutCallbackHandlerContainer);
