import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import Paper from "@material-ui/core/Paper/Paper";
import DetailedResultView from "../common/DetailedResult/DetailedResultView";
import PatientResultBasicView from "./PatientResultBasicView";
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  paper: {
    marginBottom: 30,
  },
});

class PatientResultView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
    };
  }

  handleChange = (event, value) => {
    this.setState({ selectedTab: value });
  };

  render() {
    const { classes, resultId, onClose, onSuccess, t } = this.props;
    const { selectedTab } = this.state;

    return (
      <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <Paper square className={classes.paper}>
          <Tabs
            value={this.state.selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleChange}
          >
            <Tab label={t('result_view.result')} />
            <Tab label={t('result_view.result_details')} />
          </Tabs>
        </Paper>
        {selectedTab === 0 && (
          <div style={{ overflow: "hidden" }}>
            <PatientResultBasicView
              resultId={resultId}
              onCloseButtonClick={onClose}
              onSuccessButtonClick={onSuccess}
            />
          </div>
        )}
        {selectedTab === 1 && (
          <DetailedResultView
            resultId={resultId}
            onCloseButtonClick={onClose}
          />
        )}
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
)(PatientResultView);
