import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import ReactCountryFlag from "react-country-flag";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

const languages = {
  pl: "pl",
  en: "us",
};
class ChangeLanguageComponent extends Component {
  constructor(props) {
    super();

    this.state = {
      anchor: null,
      lang: props.lang,
    };
  }

  handleClick = (event) => {
    this.setState({
      ...this.state,
      anchor: event.currentTarget,
    });
  };

  handleClose = (lang) => {
    if (typeof lang !== "string") lang = this.state.lang;
    this.setState({
      lang: lang,
      anchor: null,
    });
  };

  FlagItem = (langCode) => {
    const flagCode = languages[langCode];
    const { i18n } = this.props;

    return (
      <MenuItem
        key={langCode}
        onClick={() => {
          i18n.changeLanguage(langCode);
          this.handleClose(langCode);
        }}
      >
        {" "}
        <ReactCountryFlag countryCode={flagCode} svg />
      </MenuItem>
    );
  };

  render() {
    const { lang, anchor } = this.state;

    let currentLanguage = localStorage.getItem("i18nextLng")
      ? localStorage.getItem("i18nextLng")
      : languages[lang];

    if (currentLanguage === "en" || currentLanguage === "en-GB") {
      currentLanguage = "us";
    }

    return (
      <React.Fragment>
        <Button
          aria-owns={anchor ? "lang-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <ReactCountryFlag countryCode={currentLanguage} svg />
        </Button>

        <Menu
          id="lang-menu"
          anchorEl={anchor}
          open={Boolean(anchor)}
          onClose={this.handleClose}
        >
          {Object.keys(languages).map((langCode) => {
            return this.FlagItem(langCode);
          })}
        </Menu>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.locale,
  };
};

export default compose(
  connect(mapStateToProps),
  withTranslation()
)(ChangeLanguageComponent);
