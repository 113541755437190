import React from "react";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

const WelcomePage = () => {
    const { t } = useTranslation();

  return (
    <div>
      <Typography variant="h5" color="secondary">
        {t('welcome_message')}
      </Typography>
    </div>
  );
};


export default WelcomePage
