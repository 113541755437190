import React from "react";
import PropTypes from "prop-types";
import SelectField from "./SelectField";
import { useTranslation } from 'react-i18next';

const releaseStatuses = (types) => {
  return {
    HOME: types.home,
    LAB: types.lab,
  };
};

const PatientTypeSelectField = ({
  name,
  ...other
}) => {
const { t } = useTranslation();

  return (
    <SelectField
      name={name}
      optionmap={releaseStatuses(t('managePatients_labels.patient_types', { returnObjects: true } ))}
      label={t('managePatients_labels.patient_type')}
      {...other}
    />
  );
};

PatientTypeSelectField.propTypes = {
  name: PropTypes.string.isRequired,
};


export default PatientTypeSelectField
