import React from "react";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { FormMode } from "../FormUtils";
import Grid from "@material-ui/core/Grid/Grid";
import { useTranslation } from "react-i18next";

const styles = (theme) => ({
  button: {
    marginRight: 32,
  },
});

const FormButtons = ({
  formMode,
  editModeEnabled,
  onCloseButtonClick,
  onCancelButtonClick,
  onEditButtonClick,
  actionButtonDisabled,
  classes,
}) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {(formMode === FormMode.VIEW || formMode === FormMode.CREATE) && (
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={onCloseButtonClick}
          >
            {t('buttons.close')}
          </Button>
        </Grid>
      )}

      {formMode === FormMode.EDIT && (
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={onCancelButtonClick}
          >
            {t('buttons.cancel')}
          </Button>
        </Grid>
      )}

      {formMode === FormMode.VIEW && editModeEnabled && (
        <Grid item>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            onClick={onEditButtonClick}
            disabled={actionButtonDisabled}
          >
            {t('buttons.edit')}
          </Button>
        </Grid>
      )}
      {(formMode === FormMode.CREATE || formMode === FormMode.EDIT) && (
        <Grid item>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={actionButtonDisabled}
          >
            {formMode === FormMode.CREATE ? t('buttons.create') : t('buttons.update')}
          </Button>
        </Grid>
      )}
    </React.Fragment>
  );
};
FormButtons.propTypes = {
  formMode: PropTypes.oneOf([FormMode.VIEW, FormMode.CREATE, FormMode.EDIT]),
  onCloseButtonClick: PropTypes.func.isRequired,
  onCancelButtonClick: PropTypes.func.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  actionButtonDisabled: PropTypes.bool.isRequired,
  editModeEnabled: PropTypes.bool.isRequired,
};

export default compose(
  withStyles(styles),
)(FormButtons);
