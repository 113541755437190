import React from "react";
import TextFieldMaterialUi from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { useTranslation } from 'react-i18next';

const TextFieldComponent = ({
  input,
  label,
  meta: { touched, error },
  className,
  required,
  labels,
  style,
  ...custom
}) => {
  const { t } = useTranslation();
  return (
    <FormControl
      error
      className={className}
      required={required}
      margin={"normal"}
      style={style}
    >
      <TextFieldMaterialUi
        label={label}
        {...input}
        error={error && touched}
        required={required}
        {...custom}
      />
      <FormHelperText error={touched && error !== undefined}>
        {touched && error} {!(touched && error) && required && t('required')}
      </FormHelperText>
    </FormControl>
  );
};


export default TextFieldComponent
