import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import { TablePagination } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import LoadingIndicator from "../LoadingIndicator";
import TableBodyCell from "./TableBodyCell";
import PagedTablePageNavigator from "./PagedTablePageNavigator";
import SortedTableHeaderCell from "./SortedTableHeaderCell";
import TableHeaderCell from "./TableHeaderCell";
import Typography from "@material-ui/core/Typography";
import { withTranslation } from "react-i18next";
import compose from "recompose/compose";

const styles = (theme) => ({
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
});

class PagedTable extends React.Component {
  constructor(props) {
    super(props);

    this.changePageHandler = this.changePageHandler.bind(this);
    this.changeRowsPerPageHandler = this.changeRowsPerPageHandler.bind(this);
    this.changeSortHandler = this.changeSortHandler.bind(this);
  }

  changePageHandler(pageNumber) {
    this.props.onPageChange(pageNumber);
  }

  changeRowsPerPageHandler(event) {
    let rowsPerPage = event.target.value;
    this.props.onRowsPerPageChange(rowsPerPage);
  }

  changeSortHandler = (orderBy) => (event) => {
    this.props.onChangeSort(orderBy);
  };

  render() {
    const {
      data,
      loading,
      columns,
      classes,
      rowKey,
      emptyResultMessage,
      columnLabels,
      error,
      errorMessage,
    } = this.props;
    const { size, number, total } = this.props.page;
    const { order, orderBy } = this.props.sorting;
    const { t } = this.props

    return (
      <div>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                if (
                  column.sortable !== undefined &&
                  column.sortable === false
                ) {
                  return (
                    <TableHeaderCell type={column.type} key={column.key}>
                      {columnLabels[column.key]}
                    </TableHeaderCell>
                  );
                }
                return (
                  <SortedTableHeaderCell
                    key={column.key}
                    orderProperty={column.orderProperty}
                    type={column.type}
                    headerKey={column.key}
                    onChangeSort={this.changeSortHandler}
                    active={
                      orderBy === column.key ||
                      (column.orderProperty !== undefined &&
                        orderBy === column.orderProperty)
                    }
                    direction={order}
                  >
                    {columnLabels[column.key]}
                  </SortedTableHeaderCell>
                );
              })}
            </TableRow>
          </TableHead>
          {loading ? (
            this.loadingTableContent(columns, size)
          ) : (
            <TableBody>
              {error && this.errorTableContent(columns, errorMessage)}

              {!error &&
                data.length === 0 &&
                this.emptyResultTableContent(columns, emptyResultMessage)}

              {!error &&
                data.map((row) => {
                  return this.createRow(
                    row,
                    classes,
                    rowKey,
                    columns,
                    columnLabels
                  );
                })}
            </TableBody>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                count={total}
                rowsPerPage={size}
                page={number}
                onPageChange={this.changePageHandler}
                onRowsPerPageChange={this.changeRowsPerPageHandler}
                ActionsComponent={PagedTablePageNavigator}
                rowsPerPageOptions={[10, 25, 50]}
                style={{ paddingBottom: 20 }}
                labelRowsPerPage={t('rowsPerPage')}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  }

  createRow(row, classes, rowKey, columns) {
    return (
      <TableRow className={classes.row} key={row[rowKey]}>
        {columns.map((column) => {
          if (column.customCellRenderer !== undefined) {
            return (
              <TableBodyCell key={column.key} type={column.type}>
                {column.customCellRenderer(row[rowKey], row[column.key], row)}
              </TableBodyCell>
            );
          } else {
            return (
              <TableBodyCell key={column.key} type={column.type}>
                {row[column.key]}
              </TableBodyCell>
            );
          }
        })}
      </TableRow>
    );
  }

  emptyResultTableContent(columns, emptyResultMessage) {
    return (
      <TableRow key="emptyRow" style={{ height: 48 * 5 }}>
        <TableBodyCell key="emptyCell" colSpan={columns.length}>
          <Typography align="center"> {emptyResultMessage}</Typography>
        </TableBodyCell>
      </TableRow>
    );
  }

  errorTableContent(columns, errorMessage) {
    return (
      <TableRow key="emptyRow" style={{ height: 48 * 5 }}>
        <TableBodyCell key="emptyCell" colSpan={columns.length}>
          <Typography align="center"> {errorMessage}</Typography>
        </TableBodyCell>
      </TableRow>
    );
  }

  loadingTableContent(columns, size) {
    return (
      <TableBody>
        <TableRow key="emptyRow" style={{ height: 48 * 5 }}>
          <TableBodyCell key="emptyCell" colSpan={columns.length}>
            <LoadingIndicator />
          </TableBodyCell>
        </TableRow>
      </TableBody>
    );
  }
}

export default compose(withTranslation(), withStyles(styles))(PagedTable);
