import PropTypes from "prop-types";
import { compose } from "recompose";
import { DOCUMENTS_VERSION_SERVICE } from "../../common/services/availableServices";
import DocumentVersion from "./DocumentVersion";
import React, { Component } from "react";
import toastr from "toastr";
import PopupWindow from "../../common/components/PopupWindow";
import AddNewVersionView from "./AddNewVersionView";

import { withStyles } from "@material-ui/styles";

const styles = (theme) => ({
  root: {
    display: "flex",
    minWidth: "60vh",
  },
  container: {
    width: "100%",
  },
  inputField: {
    width: "100%",
  },
  submitRow: {
    marginTop: 20,
  },
});

class DocumentVersionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      versions: [],
      selectedVersion: null,
      addVersionModal: false,
      publishModal: false,
    };

    this.windowHandler = this.windowHandler.bind(this);
    this.versionHandler = this.versionHandler.bind(this);
    this.onSuccessPublish = this.onSuccessPublish.bind(this);
    this.getVersionPerDocName = this.getVersionPerDocName.bind(this);
  }

  componentDidMount() {
    this.getVersionPerDocName(this.props.docName);
  }

  componentDidUpdate(prevProps) {
    if (this.props.docName !== prevProps.docName) {
      this.getVersionPerDocName(this.props.docName);
    } else if (this.props.newlyPublished) {
      this.getVersionPerDocName(this.props.docName);
    }
  }

  getVersionPerDocName(docName) {
    DOCUMENTS_VERSION_SERVICE.get("name/" + docName)
      .then((versions) => {
        const versionData = versions.data;
        this.setState({ versions: versionData });
        if (versionData.length > 0) {
          this.versionHandler(versionData[0]);
        } else {
          this.updateHandler(null);
        }
      })
      .catch((error) => {
        console.error(error);
        if (typeof error.response === "undefined") return;
        toastr.error(error.response.data.message);
        this.setState(
          {
            versions: [],
            selectedVersion: null,
          },
          () => this.updateHandler(null)
        );
      });
  }

  updateDocVersion(docName, newVersion) {
    DOCUMENTS_VERSION_SERVICE.get("name/" + docName)
      .then((versions) => {
        const versionData = versions.data;
        this.setState({ versions: versionData });
        this.versionHandler(newVersion);
      })
      .catch((error) => {
        if (typeof error.response === "undefined") return;
        toastr.error(error.response.data.message);
      });
  }

  versionHandler(selectedVersion) {
    this.setState({ selectedVersion: selectedVersion }, () =>
      this.updateHandler(selectedVersion)
    );
  }

  updateHandler(selectedVersion) {
    if (this.props.selectedVersionHandler !== "undefined")
      this.props.selectedVersionHandler(selectedVersion);
  }

  newVersionOnListHandler(newVersion) {
    this.updateDocVersion(this.props.docName, newVersion);
    this.windowHandler();
  }

  windowHandler() {
    this.setState({
      addVersionModal: !this.state.addVersionModal,
    });
  }

  onSuccessPublish(selectedVersion) {
    this.setState({ selectedVersion });
  }

  render() {
    return (
      <>
        <PopupWindow
          open={this.state.addVersionModal}
          onClose={this.windowHandler}
        >
          <AddNewVersionView
            onSuccess={this.newVersionOnListHandler.bind(this)}
            onCancel={this.windowHandler}
            documentVersion={this.state.selectedVersion}
          />
        </PopupWindow>

        <DocumentVersion
          versions={this.state.versions}
          versionSelectedHandler={this.versionHandler}
          selectedVersion={this.state.selectedVersion}
          windowHandler={this.windowHandler}
        />
      </>
    );
  }
}

DocumentVersionContainer.propTypes = {
  selectedVersionHandler: PropTypes.func,
  docName: PropTypes.string.isRequired,
  newlyPublished: PropTypes.bool,
};

export default compose(withStyles(styles))(DocumentVersionContainer);
