import React, { Component } from "react";
import TextField from "../common/fields/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { required } from "../common/fieldValidators";
import { reduxForm } from "redux-form";
import Button from "@material-ui/core/Button";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import CountrySelectField from "../common/fields/selectFields/CountrySelectField";
import { withTranslation } from "react-i18next";

const styles = () => ({
  expandForm: {
    width: "100%",
  },
  nextButtonContainer: {
    float: "right",
  },
  prevButtonContainer: {
    float: "left",
  },
  formRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    alignContent: "flex-start",
  },
  city: {
    marginLeft: 32,
    flexGrow: 3,
  },
  formContainer: {
    minHeight: "650px",
    maxHeight: "650px",
  },
});

class ClinicForm extends Component {
  render() {
    const { handleSubmit, handleBack, classes, t } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1} className={classes.formContainer}>
          <Grid item xs={12}>
            <Typography variant="h6">{t("signup_labels.provide_clinic_info")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.expandForm}
              name="company"
              label={t("signup_labels.company")}
              validate={[required]}
            />
          </Grid>
          <Grid item xs={12}>
            <CountrySelectField
              name={"country"}
              className={classes.expandForm}
              validate={[required]}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.expandForm}
              name="address1"
              label={t("signup_labels.address1")}
              validate={[required]}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.expandForm}
              name="address2"
              label={t("signup_labels.address2")}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.formRow}>
              <TextField
                name={"postcode"}
                label={t("signup_labels.postcode")}
                validate={[required]}
              />
              <TextField
                name={"city"}
                label={t("signup_labels.city")}
                className={classes.city}
                validate={[required]}
              />
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Grid item xs={12} sm={6}>
            <Button
              onClick={handleBack}
              variant="outlined"
              color="primary"
              className={classes.prevButtonContainer}
            >
              {t("buttons.back")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.nextButtonContainer}
            >
              {t("buttons.next")}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
  reduxForm({
    form: "UserSignupForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })
)(ClinicForm);
