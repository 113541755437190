import { DOCUMENTS_REPRESENTATION_SERVICE } from "../../common/services/availableServices";
import React, { Component } from "react";
import DocumentRepresentation from "./DocumentRepresentation";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import PopupWindow from "../../common/components/PopupWindow";
import EditDocumentRepresentationContainer from "./UpdateDocumentRepresentationView/EditDocumentRepresentationContainer";

const styles = (theme) => ({
  root: {
    display: "flex",
    minWidth: "60vh",
  },
  container: {
    width: "100%",
  },
  inputField: {
    width: "100%",
  },
  submitRow: {
    marginTop: 20,
  },
});

class DocumentRepresentationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docView: null,
      openModal: false,
    };

    this.windowHandler = this.windowHandler.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.translation !== null && prevProps.translation == null) {
      this.getDocumentView(this.props.translation.documentRepresentationId);
    } else if (
      this.props.translation !== null &&
      this.props.translation.documentRepresentationId !==
        prevProps.translation.documentRepresentationId
    ) {
      this.getDocumentView(this.props.translation.documentRepresentationId);
    }
  }

  getDocumentView(id) {
    DOCUMENTS_REPRESENTATION_SERVICE.get("display/" + id)
      .then((docView) => {
        this.setState({ docView: docView.data.content });
      })
      .catch((error) => {
        this.setState({ docView: null });
      });
  }

  windowHandler(event, reason) {
    if (reason && reason == "backdropClick") return;
    this.setState({
      openModal: !this.state.openModal,
    });
  }

  onSuccessHandler(docText, status) {
    this.setState({ docView: docText });
    this.windowHandler();
    this.props.updateTranslationStatusHandler(status);
  }

  render() {
    const { version, translation } = this.props;
    return (
      <React.Fragment>
        <PopupWindow
          open={this.state.openModal}
          onClose={this.windowHandler}
          disableEscapeKeyDown
          disablePadding={false}
        >
          <EditDocumentRepresentationContainer
            onClose={this.windowHandler}
            onSuccess={this.onSuccessHandler.bind(this)}
            translation={this.props.translation}
            version={this.props.version}
          />
        </PopupWindow>
        {version && translation && (
          <DocumentRepresentation
            version={this.props.version}
            translation={this.props.translation}
            docText={this.state.docView}
            windowHandler={this.windowHandler}
          />
        )}
      </React.Fragment>
    );
  }
}

DocumentRepresentationContainer.propTypes = {
  translation: PropTypes.shape({
    documentRepresentationId: PropTypes.number.isRequired,
    documentVersionId: PropTypes.number.isRequired,
    textDirection: PropTypes.string.isRequired,
    languageName: PropTypes.string.isRequired,
    languageCode: PropTypes.string.isRequired,
  }),
  version: PropTypes.object,
};

export default withStyles(styles)(DocumentRepresentationContainer);
