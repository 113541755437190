import React, { Component } from "react";
import withStyles from "../../node_modules/@material-ui/core/styles/withStyles";
import { loginRequest, logoutFailed, logoutRequest } from "../Auth/actions";
import CardContent from "../../node_modules/@material-ui/core/CardContent/CardContent";
import Grid from "../../node_modules/@material-ui/core/Grid/Grid";
import Button from "../../node_modules/@material-ui/core/Button/Button";
import Card from "../../node_modules/@material-ui/core/Card/Card";
import { authenticationService } from "../index";
import { connect } from "react-redux";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const styles = () => ({
  logForm: {
    width: "450px",
    display: "inline-block",
  },
  centered: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
});

class LandingMenu extends Component {
  render() {
    const { classes, t } = this.props;
    return (
      <div style={{ textAlign: "center" }} className={classes.centered}>
        <Card className={classes.logForm}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} />
              <Grid item xs={12}>
                <Button
                  onClick={this.logInHandler.bind(this)}
                  variant="contained"
                  color="primary"
                  fullWidth={true}
                >
                  {t("landing_page.login")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth={true}
                  onClick={this.props.onResetPassword}
                >
                  {" "}
                  {t("landing_page.forget_password")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={this.props.onSignup}
                  fullWidth={true}
                >
                  {t("landing_page.register_clinic")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={this.props.onFAQShow}
                  fullWidth={true}
                >
                  {t("landing_page.faq")}
                </Button>
              </Grid>

              {/* <Grid item xs={6}>
                  <Button href={process.env.REACT_APP_API_ROOT}> {labels.see_api_docs}</Button>
              </Grid> */}
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }

  logInHandler() {
    this.props.loginRequest();
    try {
      authenticationService.login();
    } catch (e) {
      this.props.loginFailed(e);
    }
  }
}

LandingMenu.propTypes = {
  onSignup: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired,
};

function mapStateToProps({ auth }) {
  return auth;
}

export default compose(
  withStyles(styles),
  withTranslation(),
  connect(mapStateToProps, {
    loginRequest,
    logoutRequest,
    logoutFailed,
  })
)(LandingMenu);
