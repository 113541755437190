import React, { Component } from "react";
import "./App.css";
import Menu from "./Menu/Menu";
import ServerErrorHandler from "./Error/ServerErrorHandler";
import IdleMonitorContainer from "./IdleMonitor/IdleMonitorContainer";
import PermissionContainer from "./Auth/PermissionContainer";
import Routes from "./routing/Router";
import LandingPage from "./LandingPage/LandingPage";
import Utils from "../src/common/Utils";
import MainAppBar from "./MainAppBar/MainAppBar";
import Slide from "@material-ui/core/Slide/Slide";
import { Box } from "@material-ui/core";
export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open_drawer: true,
    };

    this.idleAlertTime = parseInt(
      process.env.REACT_APP_IDLE_ALERT_TIME,
      Utils.decimalSystem
    );
    this.idleLogoutTime = parseInt(
      process.env.REACT_APP_IDLE_LOGOUT_TIME,
      Utils.decimalSystem
    );

    this.handleDrawer = this.handleDrawer.bind(this);

    this.menuRef = React.createRef();
  }

  handleDrawer() {
    this.setState({ open_drawer: !this.state.open_drawer });
  }

  render() {
    return (
      <div className="main-container">
        <MainAppBar
          isOpened={this.state.open_drawer}
          drawerHandle={this.handleDrawer}
        />

        <div className="content">
          <PermissionContainer onlyForAuthenticated={true}>
            <IdleMonitorContainer
              idleAlertTime={this.idleAlertTime}
              idleLogoutTime={this.idleLogoutTime}
            />
            <ServerErrorHandler />

            <Slide
              direction="right"
              in={this.state.open_drawer}
              mountOnEnter
              unmountOnExit
            >
              <Box>
                <Menu className="side-menu"/>
              </Box>
            </Slide>

            <div className="site-content">
              <Routes />
            </div>
          </PermissionContainer>
          <PermissionContainer onlyForUnauthenticated={true}>
            <div className="outer-content">
              <LandingPage />
            </div>
          </PermissionContainer>
        </div>
      </div>
    );
  }
}
