import PropTypes from "prop-types";
import React from "react";
import { reduxForm } from "redux-form";
import Grid from "@material-ui/core/Grid/Grid";
import LanguageSelectField from "../../common/fields/selectFields/LanguageSelectField";
import { required } from "../../common/fieldValidators";
import Button from "@material-ui/core/Button/Button";
import { withStyles } from "@material-ui/core/styles/index";
import { compose } from "recompose";
import Typography from "@material-ui/core/Typography/Typography";
import { DOCUMENTS_TRANSLATION_SERVICE } from "../../common/services/availableServices";
import toastr from "toastr";
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    display: "flex",
    minWidth: "60vh",
  },
  container: {
    width: "100%",
  },
  inputField: {
    width: "100%",
  },
  submitRow: {
    marginTop: 20,
  },
});

const AddNewTranslationView = ({
  handleSubmit,
  classes,
  onCancel,
  onSuccess,
  documentVersion,
  translations,
  labels,
}) => {

  const { t } = useTranslation();

  const submitHandler = (values) => {
    const translationForm = {
      ...values,
      versionId: documentVersion.id,
    };

    DOCUMENTS_TRANSLATION_SERVICE.post(translationForm)
      .then((response) => {
        toastr.success(t('documents_section.add_translation.success'));
        onSuccess(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={classes.root}>
      <form
        noValidate
        autoComplete="off"
        className={classes.container}
        onSubmit={handleSubmit(submitHandler.bind(this))}
      >
        <Grid container spacing={1} direction="column">
          <Grid item md={12}>
            <Typography variant="h5" color="secondary">
              {t('documents_section.add_translation.title')}
            </Typography>
          </Grid>

          <Grid item>
            <LanguageSelectField
              name={"languageCode"}
              removeAll={translations}
              required
              className={classes.inputField}
              validate={[required]}
            />
          </Grid>

          <Grid
            container
            spacing={1}
            justifyContent="flex-end"
            className={classes.submitRow}
          >
            <Grid item>
              <Button onClick={onCancel}>{t('documents_section.add_translation.cancel')}</Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color={"primary"} type={"submit"}>
                {t('documents_section.add_translation.save')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

AddNewTranslationView.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  documentVersion: PropTypes.any.isRequired,
  translations: PropTypes.array,
};

export default compose(
  withStyles(styles),
  reduxForm({
    form: "documentLanguageForm",
  })
)(AddNewTranslationView);
