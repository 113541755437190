import React from "react";
import PropTypes from "prop-types";
import ServiceSelectField from "./ServiceSelectField";
import { CLINIC_SERVICE_AVAILABLE } from "../../services/availableServices";
import { useTranslation } from 'react-i18next';

const customMapperHandler = (values) => {
  return values.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });
};

const ClinicSelectField = ({ label, name, ...other }) => {
  const { t } = useTranslation();

  return (
    <ServiceSelectField
      name={name}
      service={CLINIC_SERVICE_AVAILABLE}
      customMapper={customMapperHandler}
      label={t('select_field.clinic')}
      {...other}
    />
  );
};

ClinicSelectField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ClinicSelectField
