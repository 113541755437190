import React from "react";
import PropTypes from "prop-types";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import TextField from "./TextField";
import { useTranslation } from "react-i18next";

const SearchTextField = ({ name, className }) => {
  const { t } = useTranslation();
  return (
    <TextField
      name={name}
      label={t('fields.search')}
      className={className}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
};

SearchTextField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default SearchTextField
