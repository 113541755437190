import React from "react";
import { withStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { compose } from "recompose";
import Typography from "@material-ui/core/Typography/Typography";
import Grid from "@material-ui/core/Grid/Grid";
import Button from "@material-ui/core/Button/Button";
import { UPDATE_RESULT_NOTES_SERVICE } from "../../../common/services/availableServices";
import toastr from "toastr";
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    display: "flex",
    width: "60vh",
  },
  textField: {
    width: "100%",
  },
  submitRow: {
    marginTop: 20,
  },
});

class EditNotesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: this.props.notes,
    };
  }

  changeHandler = (event) => {
    this.setState({
      notes: event.target.value,
    });
  };

  cancelButtonClickHandler = () => {
    this.props.onCancel();
  };

  updateButtonClickHandler = () => {
    UPDATE_RESULT_NOTES_SERVICE.put(this.state.notes, this.props.resultId)
      .then((response) => {
        toastr.success(
          this.props.t('detailed_result_view.notesUpdatedSuccessfully')
        );
        this.props.onSuccess();
      })
      .catch((error) => {
        toastr.error(this.props.t('detailed_result_view.notesUpdateFail'));
      });
  };

  render() {
    const { classes, t } = this.props;
    const { notes = "" } = this.state;
    const specLabels = t('detailed_result_view', { returnObjects: true });

    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item md={12}>
            <Typography variant="h5" color="secondary">
              {specLabels.updateResultNotes}
            </Typography>
            <Typography>{specLabels.notesCharacterLimit}</Typography>
          </Grid>
          <Grid item md={12}>
            <TextField
              value={notes}
              className={classes.textField}
              multiline
              rows={2}
              variant="outlined"
              onChange={this.changeHandler}
              inputProps={{
                maxLength: 255,
              }}
            />
          </Grid>

          <Grid
            container
            spacing={1}
            justifyContent="flex-end"
            className={classes.submitRow}
          >
            <Grid item>
              <Button
                variant="outlined"
                onClick={this.cancelButtonClickHandler}
              >
                {t('buttons.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color={"primary"}
                type={"submit"}
                onClick={this.updateButtonClickHandler}
              >
                {t('buttons.save')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
)(EditNotesComponent);
