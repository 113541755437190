import axios from "axios/index";

export default class RestService {

    API_URL = null;


    constructor(path) {
        this.endpointName = path;
        this.API_URL = process.env.REACT_APP_API_ROOT + path
    }

    getAll(criteria) {
        return axios.get(
            this.API_URL
            + '?'
            + this.prepareCriteriaQueryStringFromArray(criteria)
        )
    }

    get(id) {
        let urlString = this.API_URL + '/' + id;
        return axios.get(urlString)
    }

    getWithRequestParams(params) {
        let query = "?";
        Object.keys(params).forEach(param => {
            query += param + '=' + params[param] + '&';
        });

        return axios.get(this.API_URL + query.slice(0, -1));
    }

    getBlob(id) {
        return axios({
            url: this.API_URL + '/' + id,
            method: 'GET',
            responseType: 'blob'
        })
    }

    getBlobWithParams(params) {
        const crits = this.prepareCriteriaQueryString(params);
        return axios({
            url: this.API_URL + '?' + crits,
            method: 'GET',
            responseType: 'blob', // important
        })
    }

    post(values) {
        let queryString = this.API_URL;
        return axios.post(queryString, values)
    }

    putValues(values) {
        let urlString = this.API_URL;
        return axios.put(urlString, values)
    }

    put(values, id) {
        let urlString = this.API_URL + '/' + id;
        return axios.put(urlString, values)
    }

    putId(id) {
        let urlString = this.API_URL + '/' + id;
        return axios.put(urlString)
    }


    delete(id) {
        let urlString = this.API_URL + '/' + id;
        return axios.delete(urlString)
    }

    fetchData(page, sorting, criteria) {
        let pageQueryString = this.preparePageQueryString(page);
        let sortingQueryString = this.prepareSortingQueryString(sorting);
        let criteriaQueryString = this.prepareCriteriaQueryString(criteria);

        let queryString = this.constructQueryString([pageQueryString, sortingQueryString, criteriaQueryString]);
        return axios.get(queryString)

    }

    constructQueryString(queryStrings) {

        return this.API_URL + '?' + queryStrings.filter((q) => q !== "").join('&')

    }

    preparePageQueryString(pageObject) {
        const {number, size} = pageObject;

        let pageQueryStringArray = [];

        if (number !== undefined) {
            pageQueryStringArray.push(`page=${number}`)
        }
        if (size !== undefined) {
            pageQueryStringArray.push(`size=${size}`)
        }

        return pageQueryStringArray.join('&')
    }

    prepareSortingQueryString(sorting) {
        let sortingQueryStringArray = [];

        if (sorting !== undefined) {
            const {orderBy, order} = sorting;

            if (orderBy !== undefined) {
                sortingQueryStringArray.push(`sort=${orderBy}`)
            }
            if (order !== undefined) {
                sortingQueryStringArray.push(`${order}`)
            }
        }
        return sortingQueryStringArray.join(',')


    }

    prepareCriteriaQueryString(criteria) {

        let criterionQueryStringArray = [];

        for (var key in criteria) {
            if (criteria.hasOwnProperty(key)) {
                let value = criteria[key];
                if (value !== undefined && value !== '')
                    criterionQueryStringArray.push(`${key}=${value}`)
            }

        }

        return criterionQueryStringArray.join('&')

    }

    prepareCriteriaQueryStringFromArray(criteria) {
        let criterionQueryStringArray = [];

        for (let criterion in criteria) {
            if (criteria.hasOwnProperty(criterion)) {
                for (let key in criteria[criterion]) {
                    if (criteria[criterion].hasOwnProperty(key)) {
                        let value = criteria[criterion][key];
                        if (value !== undefined && value !== '')
                            criterionQueryStringArray.push(`${key}=${value}`)
                    }
                }
            }
        }

        return criterionQueryStringArray.join('&')

    }
}

