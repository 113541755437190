import React from "react";
import { AppBar, Toolbar } from "@material-ui/core/index";
import "./MainAppBar.css";
import logo from "../_media/lyfstone_logo.png";
import { withStyles } from "@material-ui/core/styles/index";
import Grid from "@material-ui/core/Grid/Grid";
import IconButton from "@material-ui/core/IconButton/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChangeLanguageComponent from "./ChangeLanguageComponent";
import LogComponent from "./LogComponent";
import PermissionContainer from "../Auth/PermissionContainer";

const styles = (theme) => ({
  container: {
    display: "flex",
  },
  logo: {
    height: "50px",
    marginLeft: "20px",
  },
  theRestOfContent: {
    flex: 1,
  },

  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: "none",
  },
});

const MainAppBar = ({ classes, drawerHandle, isOpened }) => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid container item justifyContent="flex-start" xs={3}>
            <Grid item>
              <PermissionContainer onlyForAuthenticated={true}>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  className={classes.menuButton}
                  onClick={drawerHandle}
                >
                  {isOpened ? (
                    <ChevronLeftIcon color={"primary"} />
                  ) : (
                    <MenuIcon color={"primary"} />
                  )}
                </IconButton>
              </PermissionContainer>
            </Grid>
          </Grid>
          <Grid container item xs={6} justifyContent="center">
            <Grid item>
              <img alt="logo" className={"logo"} src={logo} />
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <LogComponent />
              </Grid>
              <Grid item>
                <ChangeLanguageComponent />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(MainAppBar);
