import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { compose } from "redux";
import List from "../../node_modules/@material-ui/core/List/List";
import ListItem from "../../node_modules/@material-ui/core/ListItem/ListItem";
import ListItemText from "../../node_modules/@material-ui/core/ListItemText/ListItemText";
import Paper from "../../node_modules/@material-ui/core/Paper/Paper";
import withStyles from "../../node_modules/@material-ui/core/styles/withStyles";
import { withTranslation } from 'react-i18next';

const styles = () => ({
  list: {
    marginLeft: "2px",
  },
});

class AgreementList extends Component {
  render() {
    const { agreements, showDetails, t, classes } = this.props;
    return (
      <Grid item xs={12}>
        <Paper className={classes.list}>
          <List>
            {[...agreements].map((elem, index) => {
              return (
                <ListItem button key={index} onClick={() => showDetails(index)}>
                  <ListItemText
                    primary={`${elem["documentName"]}, ${t('myAgreements_labels.version')}: ${elem["documentVersionName"]}`}
                  />
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </Grid>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
)(AgreementList);
